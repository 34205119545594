import React, { useContext, useEffect, useState } from "react";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import AppContainer from "../../Components/Structure/AppContainer";
import AuthContext from "../../Context/AuthContext";
import ajaxTeam from "../../util/remote/ajaxTeam";
import ajaxTask from "../../util/remote/ajaxTask";
import ajaxProject from "../../util/remote/ajaxProject";
import ajaxStores from "../../util/remote/ajaxStores";

export default function InventoryOutput() {
  const { userId } = useContext(AuthContext);
  const [taskId, setTaskId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [storeID, setStoreiD] = useState("");
  const [projectId, setProjectId] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([{ product: "", quantity: "" }]);
  const [date, setDate] = useState("");
  const [reff_no, setReff_no] = useState("");

  const navigator = useNavigate();
  const handleRecordOutput = async (e) => {
    e.preventDefault();
    if (
      userId > 0 &&
      date.length > 0 &&
      taskId > 0 &&
      teamId > 0 &&
      rows.length > 0
    ) {
      setLoading(true);
      const outputData = rows.map((row) => ({
        productId: row.product,
        quantity: row.quantity,
      }));
      let data = {
        products: outputData,
        added_by: userId,
        store_id: storeID,
        date_added: date,
        task_id: taskId,
        team_id: teamId,
        delivery_note: reff_no,
      };

      const server_response = await ajaxProduct.createInventoryOutput(data);
      setLoading(false);

      if (server_response.status === "OK") {
        toast.success(server_response.message);

        setTimeout(() => {
          navigator("/inventory");
        }, 1000);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  // fetcing stores *******************************************************
  const [stores, setStores] = useState("");
  useEffect(() => {
    fetch_store();
  }, []);

  const fetch_store = async () => {
    const server_response = await ajaxStores.fetchStores();
    if (server_response.status === "OK") {
      setStores(server_response.details);
    } else {
      setStores("404");
    }
  };
  // end fetching stores ***************************************************
  const [productList, setProductList] = useState(false);
  const [taskList, setTaskList] = useState(false);
  const [teamList, setTeamList] = useState(false);
  const [projectList, setProjectList] = useState(false);

  useEffect(() => {
    getProducts();
    getTeams();
    getProjects();
  }, []);

  useEffect(() => {
    getTasks();
  }, [projectId]);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };
  const getProjects = async () => {
    const server_response = await ajaxProject.fetchProjectList();

    if (server_response.status === "OK") {
      setProjectList(server_response.details);
    }
  };

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks(projectId);

    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  const getTeams = async () => {
    const server_response = await ajaxTeam.getTeamList();

    if (server_response.status === "OK") {
      setTeamList(server_response.details);
    }
  };

  // for controlling the rows------------------
  const handleAddRow = () => {
    if (rows.length > 0) {
      const lastRow = rows[rows.length - 1];
      if (lastRow.product === "" || lastRow.quantity === "") {
        toast.error("Please complete the current row before adding a new one.");
        return;
      }
    }

    // Add a new row if no rows exist or the last row is complete
    const newRow = { product: "", quantity: "" };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };
  // end of the row controls--------------

  // handles the row items changing

  const handleProductChange = (index, selectedProduct) => {
    const updatedRows = [...rows];
    updatedRows[index].product = selectedProduct;
    setRows(updatedRows);
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity = quantity;
    setRows(updatedRows);
  };

  // ends the row item handlers---------------
  return (
    <AppContainer title="Record Output">
      <Toaster />
      <div className="card custom-card">
        <div className="card-body">
          <div>
            <h6 className="card-title mb-4">Record Inventory Output</h6>
          </div>

          <form onSubmit={(e) => handleRecordOutput(e)} method="post">
            <div className="row">
              <div className="mb-4 col-md-6">
                <label htmlFor="">Reference NO:</label>
                <input
                  type="text"
                  value={reff_no}
                  onChange={(e) => setReff_no(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Project:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setProjectId(e.project_id)}
                  getOptionLabel={(option) => option.project_name}
                  getOptionValue={(option) => option.project_id}
                  isSearchable
                  options={Array.isArray(projectList) ? projectList : []}
                  value={
                    Array.isArray(projectList) &&
                    projectList.find((value) => value.project_id === projectId)
                  }
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Task:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setTaskId(e.id)}
                  getOptionLabel={(option) => option.task_name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={Array.isArray(taskList) ? taskList : []}
                  value={
                    Array.isArray(taskList) &&
                    taskList.find((value) => value.id === taskId)
                  }
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Team:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setTeamId(e.team_id)}
                  getOptionLabel={(option) => option.team_name}
                  getOptionValue={(option) => option.team_id}
                  isSearchable
                  options={Array.isArray(teamList) ? teamList : []}
                  value={
                    Array.isArray(teamList) &&
                    teamList.find((value) => value.team_id === teamId)
                  }
                />
              </div>
              <div className="mb-4 col-md-6">
                <label htmlFor="">
                  Date:<span className="tx-danger">*</span>
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="mb-4 col-md-12">
                <label htmlFor="">
                  Source Store:<span className="tx-danger">*</span>
                </label>
                <Select
                  onChange={(e) => setStoreiD(e.id)}
                  getOptionLabel={(option) => option.storeName}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={Array.isArray(stores) ? stores : []}
                  value={
                    Array.isArray(stores) &&
                    stores.find((value) => value.id === storeID)
                  }
                />
              </div>
              <div className="mb-4 col-md-12">
                <hr class="mg-b-40" />

                <table className="table table-hover text-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th class="wd-35p" scope="col">
                        Product
                      </th>
                      <th class="wd-10p" scope="col">
                        Quantity
                      </th>

                      <th scope="col">operations</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          <Select
                            onChange={(e) => handleProductChange(index, e.id)}
                            getOptionLabel={(option) => option.product_name}
                            getOptionValue={(option) => option.id}
                            isSearchable
                            options={
                              Array.isArray(productList) ? productList : ""
                            }
                            value={
                              Array.isArray(productList) &&
                              productList.find(
                                (value) => value.id === row.product
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            min={1}
                            value={row.quantity}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={() => handleDeleteRow(index)}
                            className="btn btn-danger btn-sm"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4">
                        <button
                          type="button"
                          onClick={handleAddRow}
                          className="btn btn-primary btn-sm mr-2"
                          disabled={loading}
                        >
                          Add Row
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mb-4 col-md-12">
                {loading && (
                  <button
                    disabled
                    style={{ width: "100%" }}
                    className="btn btn-success"
                  >
                    Adding...
                  </button>
                )}

                {!loading && (
                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    className="btn btn-success"
                  >
                    Record Output
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </AppContainer>
  );
}
