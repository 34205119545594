import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import * as XLSX from "xlsx";
import SystemModal from "../../Common/SystemModal";
import Loader from "../../Common/Loader";
import ajaxAccounting from "../../../util/remote/ajaxAccounting";

const AllTransactionsExportexcel = (props) => {
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [PaymentVoucher, setPAymentVoucher] = useState([]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setToDate(today);
  }, []);

  // Function to handle footer rendering
  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={() => getPaymentVoucher(controls)}>
            Export
          </button>
        </>
      );
    }
  };

  // Function to get payment vouchers
  const getPaymentVoucher = async () => {
    if (!fromDate || !toDate) {
      toast.error("Please fill in both From Date and To Date.");
      return;
    }
    const data = {
      projectID: props.projectID,
      trans_type: props.trans_type, // Assuming trans_type and page are props
      page: props.page,
      fromDate,
      toDate,
    };
    setPAymentVoucher([]);
    setLoading(true);

    const server_response = await ajaxAccounting.getTransactionsForexport(data);
    setLoading(false);
    if (
      server_response.status === "OK" &&
      server_response.details?.length > 0
    ) {
      setPAymentVoucher(server_response.details);
    } else {
      toast.error("No data available for export");
    }
  };
  useEffect(() => {
    if (PaymentVoucher.length > 0) {
      exportToExcel();
    }
  }, [PaymentVoucher]);
  // Function to export data to Excel

  const exportToExcel = () => {
    if (Array.isArray(PaymentVoucher)) {
      const data = PaymentVoucher.map((item, key) => ({
        No: key + 1,
        Reference: item.folio_reff,
        Debited_Ledger: item.ledgerID_debited.Ledger_name,
        Credited_Ledger: item.ledgerID_credited.Ledger_name,
        Amount: item.amount.amount_p,
        Entry_type: item.Entry_type,
        Trans_type: item.trans_type,
        Description: item.description,
        Date: item.date.short_date,
        reciever: item.reciever,
        created_by: item.created_by.name,
      }));

      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "All_transactions_from_");

      const currentDate = new Date().toISOString().split("T")[0];
      const fileName = `All_transactions_from_${fromDate} to ${toDate}.xlsx`;

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  return (
    <SystemModal title="" id="model-new-ledger" size="lg" footer={RenderFooter}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="card custom-card"
                style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div>
                    <h6 className="card-title mb-4">
                      All Transactions Exporting To Excel
                    </h6>
                  </div>

                  <form method="">
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          From Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          To Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default AllTransactionsExportexcel;
