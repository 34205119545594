import { useEffect, useState } from "react";
import Select from "react-select";
import ajaxProduct from "../../util/remote/ajaxProduct";
import Loader from "../../Components/Common/Loader";
import SystemModal from "../../Components/Common/SystemModal";
import Alert from "../../Components/Common/Alert";
import toast, { Toaster } from "react-hot-toast";

const UpdateNoteItem = (props) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(props.product);
  const [description, setDescription] = useState(props.description);
  const [quantity, setQuantity] = useState(props.quantity);
  const [unitCost, setUnitCost] = useState(props.unitCost);
  const [productList, setProductList] = useState(false);
  const [info, setInfo] = useState(false);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const handleUpdate = async (e, controls) => {
    e.preventDefault();
    if (props.inventoryID > 0 || product.length > 0) {
      setLoading(true);
      const server_response = await ajaxProduct.updateInventory(
        props.inventoryID,
        product,
        quantity,
        description || "",
        unitCost
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setTimeout(() => {
          controls.close();
        }, 2000);
        toast.success(server_response.message);
        props.f(props.note);
      }
    } else {
      setInfo(<Alert type="danger" message="Complete all fields" />);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={(e) => handleUpdate(e, controls)}
          >
            Update
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Inventory Record"
      id="model-update-inventory"
      size="md"
      footer={RenderFooter}
    >
      <Toaster />
      {info}
      <div className="mb-4">
        <label htmlFor="">Product Type</label>
        <Select
          onChange={(e) => setProduct(e.id)}
          getOptionLabel={(option) => option.product_name}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(productList) ? productList : []}
          value={
            Array.isArray(productList) &&
            productList.find((value) => value.id === product)
          }
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Quantity</label>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Unit Cost</label>
        <input
          type="number"
          value={unitCost}
          onChange={(e) => setUnitCost(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Description</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateNoteItem;
