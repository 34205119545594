import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import ajaxProduct from "../../util/remote/ajaxProduct";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useStateCallback from "../../util/customHooks/useStateCallback";
import UpdateNoteItem from "./UpdateNoteItem";
import toast from "react-hot-toast";

function ListNoteProducts() {
  const [noteProducts, setNoteProducts] = useState(false);
  const [noteInfo, setNoteInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [modal, setModal] = useStateCallback(false);

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <UpdateNoteItem
          inventoryID={item.id}
          f={getNoteProducts}
          note={id}
          isOpen={true}
          product={item.product.id}
          quantity={item.quantity_in}
          description={item.description}
          unitCost={item.unit_cost.total}
        />
      )
    );
  };

  useEffect(() => {
    getNoteProducts(id);
    getNoteInfo(id);
  }, [id]);

  const getNoteProducts = async () => {
    setLoading(true);
    setNoteProducts("");
    const server_response = await ajaxProduct.fetchProductsPerNote(id);
    setLoading(false);

    if (server_response.status === "OK") {
      setNoteProducts(server_response.details);
    }
  };

  const getNoteInfo = async () => {
    const server_response = await ajaxProduct.fetchNoteInfo(id);

    if (server_response.status === "OK") {
      setNoteInfo(server_response.details);
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Delivery Notes Data"; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = ["No.", "Product Name", "Quantity", "Description"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    // data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`delivery_note_data_${new Date().toLocaleDateString()}.pdf`);
  };

  // deleting inventory
  const handleDeletingInventory = async (e, ID) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this Inventory ?"
    );

    if (isConfirmed) {
      var dataa = {
        InveID: ID,
      };
      const server_response = await ajaxProduct.DeleteInventroy(dataa);
      if (server_response.status === "OK") {
        getNoteProducts();
        setTimeout(() => {
          toast.success(server_response.message);
        }, 2000);
      } else {
        toast.error(server_response.message);
      }
    }
    return;
  };

  return (
    <AppContainer title="Delivery Note Products">
      {loading && <Loader />}
      {modal}
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              {noteInfo && (
                <div class="d-lg-flex">
                  <h2 class="card-title mb-1">{noteInfo.delivery_note}</h2>
                  <div class="ml-auto">
                    <p class="mb-1">
                      <span class="font-weight-bold">Date :</span>{" "}
                      {noteInfo.date_added}
                    </p>
                    <p class="mb-1">
                      <span class="font-weight-bold">Client :</span>{" "}
                      {noteInfo.client?.name}
                    </p>
                    <p class="mb-0">
                      <span class="font-weight-bold">Received By:</span>{" "}
                      {noteInfo.received_by?.first_name}{" "}
                      {noteInfo.received_by?.last_name}
                    </p>
                  </div>
                </div>
              )}
              <hr class="mg-b-40" />

              <table className="table table-striped table-bordered">
                <thead>
                  <th>No.</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Unit Cost</th>
                  <th>Description</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {!Array.isArray(noteProducts) && <tr>No results found</tr>}
                  {Array.isArray(noteProducts) &&
                    noteProducts.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.product.product_name}</td>
                        <td>{item.quantity_in}</td>
                        <td>{item.unit_cost.total}</td>
                        <td>{item.description}</td>
                        <td>
                          <div
                            className=""
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              padding: "5px",
                            }}
                          >
                            <button
                              style={{ borderRadius: "5px" }}
                              onClick={(e) => handleModal(e, item)}
                              className="btn  btn-warning btn-with-icon"
                            >
                              <i
                                style={{ color: "gray" }}
                                className="fe fe-edit-2"
                              ></i>
                            </button>

                            <button
                              style={{ borderRadius: "5px" }}
                              onClick={(e) =>
                                handleDeletingInventory(e, item.id)
                              }
                              className="btn  btn-danger btn-with-icon"
                            >
                              <i class="fa fa-trash-can"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div class="card-footer text-right">
              <button onClick={exportToPDF} className="btn btn-success btn-md">
                Export to PDF
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default ListNoteProducts;
