import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import ajaxClient from "../../util/remote/ajaxClient";
import ajaxComment from "../../util/remote/ajaxComment";

export default function Header() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    let body = document.getElementsByTagName("body");
    const screenWidth = window.innerWidth;

    if (screenWidth < 500) {
      body[0].classList.remove("main-sidebar-show");
    }
  }, []);

  const [clientSearchResults, setClientSearchResults] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentList, setCommentList] = useState(false);
  const onLogout = () => {
    localStorage.removeItem("projectm@user");
    navigate("/login");
    window.location.reload();
  };

  const toggleSearch = (e) => {
    const profile = document.getElementById("profile");
    const notification = document.getElementById("notification");

    if (
      profile.classList.contains("show") ||
      notification.classList.contains("show")
    ) {
      profile.classList.remove("show");
      notification.classList.remove("show");
    }
    if (e.target.parentElement.parentElement.classList.contains("show")) {
      e.target.parentElement.parentElement.classList.remove("show");
    } else {
      e.target.parentElement.parentElement.classList.add("show");
    }
  };

  const dropdown = (e) => {
    e.preventDefault();
    const search = document.getElementById("search");
    if (search.classList.contains("show")) {
      search.classList.remove("show");
    }
    if (!(e.target.offsetParent.parentElement.classList[2] === "show")) {
      e.target.offsetParent.parentElement.classList.add("show");
      e.target.offsetParent.parentElement.previousElementSibling.classList.remove(
        "show"
      );
      e.target.offsetParent.parentElement?.nextElementSibling?.classList.remove(
        "show"
      );
    } else {
      e.target.offsetParent.parentElement.classList.remove("show");
    }
  };

  const toggleSidebar = (e) => {
    e.preventDefault();
    const screenWidth = window.innerWidth;
    let body = document.getElementsByTagName("body");

    if (screenWidth < 500) {
      if (body[0].classList.contains("main-sidebar-show")) {
        body[0].classList.remove("main-sidebar-show");
      } else {
        body[0].classList.add("main-sidebar-show");
      }
    } else {
      if (body[0].classList.contains("main-sidebar-hide")) {
        body[0].classList.remove("main-sidebar-hide");
        body[0].classList.add("main-sidebar-show");
      } else {
        body[0].classList.add("main-sidebar-hide");
        body[0].classList.remove("main-sidebar-show");
      }
    }
  };

  const search = async (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    if (e.target.value.length > 0) {
      setLoading(true);
      const server_response = await ajaxClient.searchClients(e.target.value);
      if (server_response.status === "OK") {
        setTimeout(() => {
          setClientSearchResults(server_response.details.clients);
          setLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <div className="main-header side-header sticky">
      <div className="container-fluid">
        <div className="main-header-left">
          <Link className="main-logo d-lg-none" href="index-2.html">
            <img
              src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              style={{ width: 100 }}
              src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
              className="header-brand-img icon-logo"
              alt="logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
              className="header-brand-img desktop-logo theme-logo"
              alt="logo"
            />
            <img
              src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
              className="header-brand-img icon-logo theme-logo"
              alt="logo"
            />
          </Link>
          <Link
            className="main-header-menu-icon"
            href="#"
            id="mainSidebarToggle"
            onClick={toggleSidebar}
          >
            <span></span>
          </Link>
        </div>
        <div className="main-header-right">
          <div className="dropdown d-md-flex header-search">
            <Link
              className="nav-link icon header-search"
              onClick={toggleSearch}
            >
              <i className="fe fe-search"></i>
            </Link>
            <div
              className="dropdown-menu"
              id="search"
              style={{ width: "450px" }}
            >
              <div className="main-form-search p-2 ">
                <input
                  onChange={search}
                  className="form-control"
                  placeholder="Search clients by name..."
                  type="search"
                />
                <button className="btn">
                  <i className="fe fe-search"></i>
                </button>
              </div>

              {searchData === "" ? (
                ""
              ) : loading ? (
                <div className="text-center">
                  <div className="lds-circle">
                    <div></div>
                  </div>
                </div>
              ) : (
                <div className="border">
                  <div className="card-body tab-content">
                    <div
                      className="table-responsive"
                      style={{ overflowY: "auto" }}
                    >
                      {Array.isArray(clientSearchResults)
                        ? Array.isArray(clientSearchResults) &&
                          clientSearchResults.map((item, key) => (
                            <div className="list-group">
                              <Link
                                className="list-group-item list-group-item-action"
                                to={`/clients/client-profile/${item.client_id}`}
                              >
                                <h6 className="">{item.name}</h6>
                                <small className="tx-11 tx-black-500">
                                  Email:{" "}
                                  <b>
                                    <strong>{item.email}</strong>
                                  </b>
                                </small>
                                <br />
                                <small className="tx-11 tx-black-500">
                                  Address:{" "}
                                  <b>
                                    <strong>{item.address}</strong>
                                  </b>
                                </small>
                              </Link>
                            </div>
                          ))
                        : clientSearchResults}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={dropdown}
            id="notification"
            className="dropdown main-header-notification"
          >
            <Link className="nav-link icon" href="#">
              <i className="fe fe-bell"></i>
              <span className="pulse bg-danger"></span>
            </Link>
            <div className="dropdown-menu">
              <div className="header-navheading">
                <p className="main-notification-text">
                  You have 1 unread notification
                  <span className="badge badge-pill badge-primary ml-3">
                    View all
                  </span>
                </p>
              </div>
              <div className="main-notification-list">
                {Array.isArray(commentList) &&
                  commentList.map((item) => (
                    <div className="media new">
                      <div className="main-img-user online">
                        <img alt="avatar" src="assets/img/users/5.jpg" />
                      </div>
                      <div className="media-body">
                        <p>{item.comment_id.comment} </p>
                        <span>{item.comment_id.created_at.when}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="dropdown main-profile-menu" id="profile">
            <Link onClick={dropdown} className="main-img-user" href="#">
              <img alt="avatar" src={`${process.env.PUBLIC_URL}/avatar.png`} />
            </Link>
            <div className="dropdown-menu">
              <div className="header-navheading">
                {
                  <>
                    <h6 className="main-notification-title">
                      {user.first_name + " " + user.last_name}
                    </h6>
                    <p className="main-notification-text">
                      {user.role?.role_name}
                    </p>
                  </>
                }
              </div>
              <Link className="dropdown-item border-top" to="/profile-user">
                <i className="fe fe-user"></i> My Profile
              </Link>

              <Link className="dropdown-item" to="" onClick={onLogout}>
                <i className="fe fe-power"></i> Sign Out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
