import { useEffect, useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import SystemModal from "../Common/SystemModal";
import numberToWords from "number-to-words";

const PaymentVoucherPrinting = (props) => {
  const Print = (e, controls) => {
    e.preventDefault();
    const printContents = document.getElementById("printablediv").innerHTML;
    const originalTitle = document.title;
    const newWindow = window.open("", "_blank");

    // Construct the new page with inline CSS
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${originalTitle} - Print</title>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: .5cm; width: 21cm; height: 29.7cm;">
          ${printContents}
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to finish writing
    newWindow.focus(); // Bring the window to the front
    newWindow.print(); // Trigger the print
    newWindow.onafterprint = () => {
      controls.close();
      newWindow.close(); // Automatically close the window after printing
    };
  };

  const [vouchers, setVouchers] = useState("");
  const [folio, setFolio] = useState("");
  const [folio_sum, seTfolio_sum] = useState("");
  const [FolioD, setFolioD] = useState("");

  const folioID = props.folioID;
  const type = props.type;
  const getPaymentVoucher = async () => {
    var data = {
      folioID: folioID,
      type: type,
    };
    const server_response = await ajaxAccounting.getFolioVouchersList(data);
    if (server_response.status === "OK") {
      setVouchers(server_response.details.data_list);
      setFolio(server_response.details.folio);
      setFolioD(server_response.details.folio_date);
      seTfolio_sum(server_response.details.folio_sum);
    } else {
      setVouchers("404");
    }
  };

  const amountToWords = (amount) => {
    const words = numberToWords.toWords(amount);
    return words.charAt(0).toUpperCase() + words.slice(1);
  };
  useEffect(() => {
    getPaymentVoucher();
  }, []);

  const RenderFooter = (controls) => {
    return (
      <>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={controls.close}
        >
          Close
        </button>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={(e) => Print(e, controls)}
        >
          Print
        </button>
      </>
    );
  };

  return (
    <SystemModal
      title="Payment Voucher Printing"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}
    >
      <div id="printablediv">
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            backgroundImage: `url(${process.env.PUBLIC_URL}/template/assets/images/doc_back.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "21cm",
            padding: "2cm",
          }}
        >
          {/* Header Section */}
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            {/* address data */}
            <div
              className=""
              style={{
                width: "70%",
                lineHeight: "6px",
                marginRight: "30px",
              }}
            >
              <h3 align="left">BERICOT AFRICA LIMITED</h3>
              <p style={{ fontSize: "14px" }} align="left">
                P.O.BOX 28775 KAMPALA
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                PLOT 79B MAKAMBA ROAD
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                TEL :+256701666160
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                EMAIL :info@bericot.africa
              </p>
              <div align="left " style={{ marginTop: "10px" }}>
                PAYMENT VOUCHER NO:
                <span className="text-info">{folio}</span>
              </div>
              {/* <div align="left " style={{ marginTop: "10px" }}>
                <p>
                  Date :{" "}
                  <span style={{ color: "red" }}>{FolioD.short_date}</span>
                </p>
              </div> */}
            </div>

            {/* logo data */}
            <div
              className=""
              style={{
                width: "20%",
              }}
            >
              <img
                style={{
                  position: "relative",
                  height: "100px",
                  width: "200px",
                  marginRight: "100px",
                }}
                src={`${process.env.PUBLIC_URL}/template/assets/images/bricote_logo.png`}
                alt="Logo"
              />

              <p>
                Date :
                <span style={{ color: "red", marginTop: "40px" }}>
                  {FolioD.short_date}
                </span>
              </p>
            </div>
          </div>

          {/* Items Table */}
          <div
            style={{
              width: "100%",
              height: "70%", // Set the table height to 70% of the page
              border: "2px groove grey",
              display: "flex",
              flexDirection: "column",
              justifyContent:
                "space-between" /* This will push the bottom element to the bottom */,
            }}
          >
            <table
              style={{
                width: "100%",
                height: "auto%", // Set the table height to 70% of the page
                borderCollapse: "collapse",
                marginBottom: "10px",
                tableLayout: "fixed",
                border: "2px groove grey",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgba(51, 181, 255, 0.7)",
                    color: "#fff",
                    height: "30px",
                    textAlign: "center",
                  }}
                >
                  <th>No</th>
                  <th>project</th>
                  <th>Ledger</th>
                  <th>DETAILS</th>
                  <th>COST CENTER</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(vouchers) &&
                  vouchers.map((item, key) => (
                    <tr style={{ height: "20px" }} key={key}>
                      <td
                        style={{
                          borderBottom: "0px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {key + 1}
                      </td>
                      <td
                        style={{
                          borderBottom: "0px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {item.project.project_name}
                      </td>
                      <td
                        style={{
                          borderBottom: "0px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {item.ledgerID_debited.Ledger_name}
                      </td>
                      <td
                        style={{
                          borderBottom: "0px solid #ddd",
                          padding: "10px",
                          textAlign: "center",
                        }}
                      >
                        {item.description}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          borderBottom: "0px solid #ddd",
                        }}
                      >
                        {item.catID.item}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          borderBottom: "0px solid #ddd",
                        }}
                      >
                        {item.amount.amount_c}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          borderBottom: "0px solid #ddd",
                        }}
                      >
                        {item.date.short_date}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <table
              style={{
                width: "100%",
                height: "auto%",
                borderCollapse: "collapse",
                marginBottom: "10px",

                border: "2px groove grey",
              }}
            >
              <tr>
                <td style={{ width: "30px" }}></td>
                <td style={{ width: "30px" }}></td>
                <td style={{ width: "30px" }}></td>
                <td style={{ width: "30px" }}></td>
                <td style={{ width: "30px" }}>
                  <b>Total Amount: </b>
                </td>
                <td style={{ width: "30px" }}>
                  <span>
                    Ugx
                    {folio_sum && " " + folio_sum.folio_sum_c} /=
                  </span>
                </td>
              </tr>
            </table>
          </div>

          {/* Total Section */}
          <div
            style={{
              textAlign: "left",
              marginTop: "10px",
            }}
          >
            <p></p>
            <p>
              <b>Amount In Words:</b>{" "}
              <span style={{ color: "grey" }}>
                <u>
                  {folio_sum && amountToWords(folio_sum.folio_sum)} shillings
                </u>
              </span>
            </p>
          </div>
          <hr style={{ border: "1px grey dotted" }} />
          {/* Footer Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "10px",
              position: "bottom",
              color: "grey",
            }}
          >
            <div>
              <p>Prepared By: --------------------------</p>
              <p>Authorised By:--------------------------</p>
            </div>
            <div>
              <p>Recieved By:--------------------------</p>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default PaymentVoucherPrinting;
