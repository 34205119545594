import { useEffect, useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import SystemModal from "../Common/SystemModal";
import numberToWords from "number-to-words";

const RecieptsPrinting = (props) => {
  const Print = (e, controls) => {
    e.preventDefault();
    const printContents = document.getElementById("printablediv").innerHTML;
    const originalTitle = document.title;
    const newWindow = window.open("", "_blank");

    // Construct the new page with inline CSS
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${originalTitle} - Print</title>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: .5cm; width: 21cm; height: 29.7cm;">
          ${printContents}
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to finish writing
    newWindow.focus(); // Bring the window to the front
    newWindow.print(); // Trigger the print
    newWindow.onafterprint = () => {
      controls.close();
      newWindow.close(); // Automatically close the window after printing
    };
  };

  const [vouchers, setVouchers] = useState("");
  const [project, setproject] = useState("");
  const [folio, setFolio] = useState("");
  const [folio_sum, seTfolio_sum] = useState("");
  const folioID = props.folioID;
  const type = props.type;
  const getPaymentVoucher = async () => {
    var data = {
      folioID: folioID,
      type: type,
    };
    const server_response = await ajaxAccounting.getFolioVouchersList(data);
    if (server_response.status === "OK") {
      setVouchers(server_response.details.data_list);
      setproject(server_response.details.project);
      setFolio(server_response.details.folio);
      seTfolio_sum(server_response.details.folio_sum);
    } else {
      setVouchers("404");
    }
  };

  const amountToWords = (amount) => {
    const words = numberToWords.toWords(amount);
    return words.charAt(0).toUpperCase() + words.slice(1);
  };
  useEffect(() => {
    getPaymentVoucher();
  }, []);

  const RenderFooter = (controls) => {
    return (
      <>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={controls.close}
        >
          Close
        </button>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={(e) => Print(e, controls)}
        >
          Print
        </button>
      </>
    );
  };

  return (
    <SystemModal
      title="Receipts Printing"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}
    >
      <div id="printablediv">
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            backgroundImage: `url(${process.env.PUBLIC_URL}/template/assets/images/doc_back.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
            padding: "2cm",
          }}
        >
          {/* Header Section */}
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            {/* company details  */}
            <div
              style={{
                width: "70%",
                lineHeight: "6px",
                marginRight: "30px",
              }}
            >
              <h3 align="left">BERICOT AFRICA LIMITED</h3>
              <p style={{ fontSize: "14px" }} align="left">
                P.O.BOX 28775 KAMPALA
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                PLOT 79B MAKAMBA ROAD
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                TEL :+256701666160
              </p>
              <p style={{ fontSize: "14px" }} align="left">
                EMAIL :info@bericot.africa
              </p>
              <div align="center ">
                Receipt NO:
                <span style={{ color: "red" }}>{folio}</span>
              </div>
            </div>

            {/* logo  */}
            <div
              style={{
                width: "20%",
              }}
            >
              <img
                style={{
                  position: "relative",
                  height: "100px",
                  width: "200px",
                  marginRight: "100px",
                }}
                src={`${process.env.PUBLIC_URL}/template/assets/images/bricote_logo.png`}
                alt="Logo"
              />
            </div>
          </div>
          <hr style={{ border: "1px grey dotted" }} />
          <div
            style={{
              width: "100%",
              height: "30%",
              fontSize: "20px",
              lineHeight: "30px",
              // borderCollapse: "collapse",

              // border: "2px groove grey",
            }}
          >
            <div className="row">
              <div style={{ width: "98%" }}>
                <div>
                  Reciever: &nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      color: "grey",
                      textDecoration: "underline dotted",
                    }}
                  >
                    {vouchers && vouchers[0].reciever}
                  </span>
                </div>
              </div>
              <div style={{ width: "98%" }}>
                <div>
                  Amount In Words:
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span
                    style={{
                      color: "grey",
                      textDecoration: "underline dotted",
                    }}
                  >
                    {folio_sum && amountToWords(folio_sum.folio_sum)} shillings
                  </span>
                </div>
              </div>
              <div style={{ width: "98%" }}>
                <div>
                  Reason:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <spanbetweeen
                    style={{
                      color: "grey",
                      textDecoration: "underline dotted",
                    }}
                  >
                    {vouchers && vouchers[0].description}
                  </spanbetweeen>
                </div>
              </div>
              <div style={{ width: "98%" }}>
                <div>
                  Payment Method:
                  <ul
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                    }}
                  >
                    <li
                      style={{
                        listStyle: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input type="checkbox" style={{ marginRight: "5px" }} />
                      Check
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input type="checkbox" style={{ marginRight: "5px" }} />
                      Cash
                    </li>
                    <li
                      style={{
                        listStyle: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input type="checkbox" style={{ marginRight: "5px" }} />
                      Direct Bank Transfer
                    </li>
                  </ul>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <div>
                  Amount:
                  <span
                    style={{
                      color: "grey",
                      border: "1px grey solid",
                      padding: "3px",

                      textDecoration: "underline dotted",
                    }}
                  >
                    {folio_sum && folio_sum.folio_sum_c}
                  </span>
                </div>
                <div>
                  Payment Date:&nbsp;&nbsp;
                  <span
                    style={{
                      color: "grey",
                      textDecoration: "underline dotted",
                    }}
                  >
                    {vouchers && vouchers[0].date.date}
                  </span>
                </div>
              </div>

              <div style={{ width: "300px", justifyContent: "left" }}>
                <div>
                  Balance:
                  <span
                    style={{
                      color: "grey",
                    }}
                  >
                    ______________________________
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ border: "1px grey dotted" }} />
          {/* Footer Section */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "5px",
              position: "bottom",
              color: "black",
            }}
          >
            <div>
              <p>Prepared By: --------------------------</p>
            </div>
            <div>
              <p>Signature /Stamp :--------------------------</p>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default RecieptsPrinting;
