import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
const TransactionUpdater = (props) => {
  const data = props.data;
  console.log(data);

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(data.amount.amount);
  const [reciever, setReciever] = useState(data.reciever);
  const [date, setDate] = useState(data.date.db);

  const [LedgerID_credited, setLedgerID_credited] = useState(
    data.ledgerID_credited.id
  );
  const [LedgerID_debited, setLedgerID_debited] = useState(
    data.ledgerID_debited.id
  );

  const [projectID, setProjectID] = useState(
    data.projectID ? data.projectID.id : ""
  );
  const [description, setDescription] = useState(data.description);
  const [employees, setEmployees] = useState(props.employees);
  const [projects, setProjects] = useState("");

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };

  const [Ledgers, setLedgers] = useState("");
  const getDebitlegders = async () => {
    const server_response = await ajaxAccounting.getALlLedgersMinData();
    if (server_response.status === "OK") {
      setLedgers(server_response.details);
    } else {
      setLedgers("404");
    }
  };

  // fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
    getProjects();
  }, []);

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (amount === "" || date === "" || description === "") {
      toast.error("Please complete the form.");
    } else {
      setLoading(true);

      const data = {
        reference: props.data.reference,
        amount: amount,
        reciever: reciever,
        date: date,
        description: description,
        projectID: projectID,
        LedgerID_debited: LedgerID_debited,
        LedgerID_credited: LedgerID_credited,
      };
      const server_response = await ajaxAccounting.Update_payment_voucher(data);

      setLoading(false);
      if (server_response.status === "OK") {
        controls.close();
        toast.success(server_response.message);
        props.function(); // Call the passed function
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}
          >
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Transaction "
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}
    >
      <div className="row">
        {/* <div className="mb-4 col-md-12">
          <ul
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              borderBottom: "dotted 1px grey",
              backgroundColor: "transparent",
            }}
          >
            <li>
              <span>
                Account_debited: <u>{data.ledgerID_debited.Ledger_name}</u>
              </span>
            </li>
            <li>
              <span>
                Account_credited: <u>{data.ledgerID_credited.Ledger_name}</u>
              </span>
            </li>
          </ul>
        </div> */}

        {data.projectID && (
          <div className="mb-4 col-md-12">
            <b>Select Project:</b>
            <br />
            <Select
              onChange={(e) => setProjectID(e.id)}
              getOptionLabel={(option) => option.project_name}
              getOptionValue={(option) => option.id}
              isSearchable
              options={projects}
              value={
                Array.isArray(projects) &&
                projects.find((value) => value.id === projectID)
              }
            />
          </div>
        )}

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Select Account To Debit
            <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_debited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Array.isArray(Ledgers) ? Ledgers : []}
            value={
              LedgerID_debited
                ? Array.isArray(Ledgers) &&
                  Ledgers.find((value) => value.id === LedgerID_debited)
                : ""
            }
          />
        </div>

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Select Account To Credit
            <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_credited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Array.isArray(Ledgers) ? Ledgers : []}
            value={
              LedgerID_credited
                ? Array.isArray(Ledgers) &&
                  Ledgers.find((value) => value.id === LedgerID_credited)
                : ""
            }
          />
        </div>

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Amount
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Reciever
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={reciever}
            onChange={(e) => setReciever(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Date
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Description
            <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"
          ></textarea>
        </div>
      </div>
    </SystemModal>
  );
};

export default TransactionUpdater;
