import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async createMeeting(data) {
    let response = await apiCall("meeting/create", data);
    return response;
  },

  async fetchMeetingRecords(data) {
    let response = await apiCall("meeting/list", data);

    return response;
  },
};
