import { useEffect, useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import SystemModal from "../Common/SystemModal";
import numberToWords from "number-to-words";

const InvoicePrintable = (props) => {
  const Print = (e, controls) => {
    e.preventDefault();
    const printContents = document.getElementById("printablediv").innerHTML;
    const originalTitle = document.title;
    const newWindow = window.open("", "_blank");

    // Construct the new page with inline CSS
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${originalTitle} - Print</title>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: .5cm; width: 21cm; height: 29.7cm;">
          ${printContents}
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to finish writing
    newWindow.focus(); // Bring the window to the front
    newWindow.print(); // Trigger the print
    newWindow.onafterprint = () => {
      newWindow.close(); // Automatically close the window after printing
      controls.close();
    };
  };

  const [inv_info, setInvo_ifo] = useState("");
  const [clientinfo, setClientinfo] = useState("");
  const [invo_details, setInvo_details] = useState("");

  const getPaymentVoucher = async () => {
    var data = {
      InvoID: props.id,
    };
    const server_response = await ajaxAccounting.getInvoice_and_details(data);
    if (server_response.status === "OK") {
      setInvo_ifo(server_response.details.info);
      setInvo_details(server_response.details.details);
      setClientinfo(server_response.details.clientinfo);
    } else {
    }
  };

  const amountToWords = (amount) => {
    const words = numberToWords.toWords(amount);
    return words.charAt(0).toUpperCase() + words.slice(1);
  };
  useEffect(() => {
    getPaymentVoucher();
  }, []);

  const RenderFooter = (controls) => {
    return (
      <>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={controls.close}
        >
          Close
        </button>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={(e) => Print(e, controls)}
        >
          Print
        </button>
      </>
    );
  };

  return (
    <SystemModal
      title="Invoice Printing"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}
    >
      <div id="printablediv">
        <div
          style={{
            fontFamily: "Arial, sans-serif",
            backgroundImage: `url(${process.env.PUBLIC_URL}/template/assets/images/doc_back.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "21cm",
            padding: "1cm",
          }}
        >
          {/* Header Section */}
          <div
            className="row"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "10px",
            }}
          >
            {/* company details  */}
            <div
              className=""
              style={{
                width: "80%",
                lineHeight: "4px",
                alignItems: "left",
              }}
            >
              <h3 align="center">BERICOT AFRICA LIMITED</h3>
              <p style={{ fontSize: "14px" }} align="center">
                P.O.BOX 28775 KAMPALA
              </p>
              <p style={{ fontSize: "14px" }} align="center">
                PLOT 79B MAKAMBA ROAD
              </p>
              <p style={{ fontSize: "14px" }} align="center">
                TEL :+256701666160
              </p>
              <p style={{ fontSize: "14px" }} align="center">
                EMAIL :info@bericot.africa
              </p>
              <h4 align="center">Invoice: {inv_info.Invo_no}</h4>
            </div>
            {/* company logo  */}
            <div
              className=""
              style={{
                width: "20%",
              }}
            >
              <img
                style={{
                  position: "absolute",
                  height: "100px",
                  width: "100px",
                }}
                src={`${process.env.PUBLIC_URL}/template/assets/images/bricote_logo.png`}
                alt="Logo"
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              position: "bottom",
              color: "grey",
              width: "100%",
            }}
          >
            <div>
              <div
                className=""
                style={{
                  width: "",
                  lineHeight: "4px",
                  alignItems: "left",
                }}
              >
                <h4>
                  <b>DR</b>
                </h4>
                <h5 align="left">
                  <b>{clientinfo && clientinfo.name}</b>
                </h5>
                <p style={{ fontSize: "14px" }} align="left">
                  {clientinfo && clientinfo.p_o_box}
                </p>
                <p style={{ fontSize: "14px" }} align="left">
                  {clientinfo && clientinfo.location}
                </p>
                <p style={{ fontSize: "14px" }} align="left">
                  TEL :+{clientinfo && clientinfo.contact}
                </p>
                <p style={{ fontSize: "14px" }} align="left">
                  EMAIL : {clientinfo && clientinfo.email}
                </p>
              </div>
              <p>
                <b>Project_name</b>: {inv_info.project_name}
              </p>
            </div>
            <div>
              <p>Invoice Date :{inv_info.date}</p>
              <p>
                Ref No.{" "}
                <span style={{ color: "blue" }}> {inv_info.ref_no} </span>
              </p>
              <p>
                LPO No.:{" "}
                <span style={{ color: "blue" }}> {inv_info.lpo_no}</span>
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "50%", // Set the table height to 70% of the page
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent:
                "space-between" /* This will push the bottom element to the bottom */,
            }}
          >
            <table
              style={{
                width: "100%",
                height: "auto%", // Set the table height to 70% of the page
                borderCollapse: "collapse",
                tableLayout: "fixed",
                border: "1px groove grey",
                color: "#fff",
                padding: "5px",
                textAlign: "center",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "rgba(51, 181, 255, 0.7)",
                    height: "30px",
                  }}
                >
                  <th>No</th>
                  <th
                    style={{
                      width: "300px",
                    }}
                  >
                    Details
                  </th>
                  <th>Units</th>
                  <th>Rate</th>
                  <th>Quantity</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(invo_details) &&
                  invo_details.map((item, key) => (
                    <>
                      <tr
                        style={{
                          color: "black",
                          height: "40px",
                          borderBottom: "1px grey solid",
                        }}
                        key={key}
                      >
                        <td style={{ width: "30px" }}>{key + 1}</td>
                        <td
                          style={{
                            width: "300px",
                            wordWrap: "break-word",
                            overflowWrap: "break-word",
                          }}
                        >
                          {item.invoicable_item}
                        </td>
                        <td style={{ width: "80px" }}>{item.unit}</td>
                        <td>{item.rate.rate_c}</td>
                        <td>{item.quantity.quantity_c}</td>
                        <td>{item.amount.amount_c}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>

          {/* Totals Section */}
          <div style={{ display: "block", marginTop: "20px" }}>
            <div
              className=""
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div className=""></div>
              <div style={{ float: "right", width: "50%" }}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    border: "2px groove grey",
                    textAlign: "center",
                  }}
                >
                  <tbody>
                    <tr
                      style={{
                        color: "black",
                        borderBottom: "1px grey solid",
                      }}
                    >
                      <td style={{ width: "150px" }}>
                        <b> Amount:</b>
                      </td>
                      <td style={{ width: "150px" }}>
                        <span>
                          Ugx{" "}
                          {inv_info.amount_crude &&
                            inv_info.amount_crude.amount_crude_c}
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        color: "black",
                        borderBottom: "1px grey solid",
                      }}
                    >
                      <td style={{ width: "150px" }}>
                        <b>V.A.T(18%):</b>
                      </td>
                      <td style={{ width: "150px" }}>
                        <span>Ugx {inv_info.vat && inv_info.vat.vat_c}</span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        color: "black",
                        borderBottom: "1px grey solid",
                      }}
                    >
                      <td style={{ width: "150px" }}>
                        <b>Total Amount:</b>
                      </td>
                      <td style={{ width: "150px" }}>
                        <span>
                          Ugx{" "}
                          {inv_info.Amount_vated &&
                            inv_info.Amount_vated.Amount_vated_c}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <hr style={{ border: "1px grey dotted", margin: "10px 0" }} />
            {/* Amount In Words Section */}
            <div style={{ textAlign: "left", marginTop: "10px" }}>
              <p>
                <b>Amount In Words:</b>{" "}
                <span style={{ color: "grey" }}>
                  <u>
                    {inv_info.Amount_vated &&
                      amountToWords(inv_info.Amount_vated.Amount_vated)}{" "}
                    shillings
                  </u>
                </span>
              </p>
            </div>

            {/* Footer Section */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "10px",
                color: "grey",
              }}
            >
              <div>
                <p>Authorized By: --------------------------</p>
              </div>
              <div>
                <p>Printed on: {new Date().toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default InvoicePrintable;
