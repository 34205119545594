import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import TableHeader from "../Components/Common/TableHeader";
import { Toaster, toast } from "react-hot-toast";
import AddMinutes from "../Components/Team/AddMinutes";
import ajaxMeeting from "../util/remote/ajaxMeeting";
import useStateCallback from "../util/customHooks/useStateCallback";
import Loader from "../Components/Common/Loader";
import dictionary from "../util/dictionary";

export default function MeetingRecords() {
  const [meetingRecords, setMeetingRecords] = useState(false);
  const [modal, setModal] = useStateCallback(false);
  const [loading, setLoading] = useState(false);

  const getMinutes = async () => {
    setLoading(true);
    const server_response = await ajaxMeeting.fetchMeetingRecords();
    setLoading(false);
    if (server_response.status === "OK") {
      setMeetingRecords(server_response.details);
    } else {
      setMeetingRecords("404");
    }
  };
  const handleModal = () => {
    setModal(false, () =>
      setModal(<AddMinutes f={getMinutes} isOpen={true} />)
    );
  };

  useEffect(() => {
    getMinutes();
  }, []);

  return (
    <AppContainer title="Meeting Records">
      <Toaster position="top-center" reverseOrder={false} />
      {modal}
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Meeting Records"
              subtitle="List of all the meetings conducred"
              viewButton={
                <a
                  href="#"
                  onClick={handleModal}
                  className="btn btn-primary btn-sm"
                  style={{ float: "right" }}>
                  Register Meeting Minutes
                </a>
              }
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">Meeting Title</th>
                    <th scope="col"> Date Held</th>
                    <th scope="col"> Meeting Type</th>
                    <th scope="col"> File</th>
                    <th scope="col"> Additional File</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(meetingRecords) &&
                  meetingRecords.length > 0 ? (
                    meetingRecords.map((item, key) => (
                      <tr key={key}>
                        <td>{item.meeting_title}</td>
                        <td>{item.date_held}</td>
                        <td>{item.type}</td>
                        <td>
                          <a
                            target="_blank"
                            href={dictionary.apiHost + item.minutes}
                            download>
                            Download Minutes
                          </a>
                        </td>
                        <td>
                          <a
                            target="_blank"
                            href={dictionary.apiHost + item.additional_file}
                            download>
                            Download Additional File
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No meeting records yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {loading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}
