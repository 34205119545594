import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import toast, { Toaster } from "react-hot-toast";
import InvoiceCreate from "./InvoiceCreate";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import ajaxProject from "../../util/remote/ajaxProject";
import Select from "react-select";
import ajaxClient from "../../util/remote/ajaxClient";
import InvoicePrintable from "./InvoicePrintable";
import useStateCallback from "../../util/customHooks/useStateCallback";
import InvoicesExportexcel from "./accountsExportModals/InvoicesExportexcel";
import InvoicesExportPdf from "./accountsExportModals/InvoicesExportPdf";

function InvoicesPage(props) {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("create_Budget_expense");
  const [projectID, setprojectID] = useState("");
  const [clientID, setClientID] = useState("");

  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTo_date(today);
  }, []);

  const setFetch_data = () => {
    getInvoices();
  };

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };
  const [clients, setClients] = useState("");
  const getClients = async () => {
    const server_response = await ajaxClient.fetchProjectClientList_min_data();
    if (server_response.status === "OK") {
      setClients(server_response.details);
    } else {
      setClients("404");
    }
  };

  const [invoices, setInvoices] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const getInvoices = async () => {
    var data = {
      projectID: projectID,
      clientID: clientID,
      page: page,
      start_date: start_date,
      to_date: to_date,
    };
    setMeta("");
    setInvoices("");
    const server_response = await ajaxAccounting.fetchInvoices(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setInvoices(server_response.details.list);
    } else {
      setInvoices("404");
    }
  };

  useEffect(() => {
    getProjects();
    getClients();
  }, []);
  useEffect(() => {
    getInvoices();
  }, [projectID, clientID, page]);

  // pagination handlers
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };
  const [handlePrint, setInvoicePrint] = useStateCallback(false);

  const handleInoviceView = (e, id) => {
    e.preventDefault();
    setInvoicePrint(false, () =>
      setInvoicePrint(<InvoicePrintable isOpen={true} id={id} />)
    );
  };
  const [export_to_excel, setExport_to_excel] = useStateCallback(false);
  const handleExportToExcel = (e) => {
    e.preventDefault();
    setExport_to_excel(false, () =>
      setExport_to_excel(
        <InvoicesExportexcel clientID={clientID} projectID={projectID} />
      )
    );
  };
  const [export_to_Pfd, setExport_to_Pfd] = useStateCallback(false);
  const handleExportToPDF = (e) => {
    e.preventDefault();
    setExport_to_Pfd(false, () =>
      setExport_to_Pfd(
        <InvoicesExportPdf clientID={clientID} projectID={projectID} />
      )
    );
  };

  const DeleteTransaction = async (e, InvoiceID) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to Delete this item ?"
    );

    if (isConfirmed) {
      var dataa = {
        reff: InvoiceID,
      };
      const server_response = await ajaxAccounting.DeleteinvoiceTransaction(
        dataa
      );

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        getInvoices();
      } else {
        toast.error(server_response.message);
      }
    }
  };
  return (
    <div>
      <AppContainer title="Accounts / invoices">
        <div className="row">
          <Toaster />
          {export_to_excel}
          {handlePrint}
          {export_to_Pfd}
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              {/* Tabs navigation */}
              <nav className="nav main-nav-line">
                <a
                  className={`nav-link ${
                    activeTab === "create_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("create_Budget_expense")}
                  style={{ cursor: "pointer" }}
                >
                  Create Invoice
                </a>
                <a
                  className={`nav-link ${
                    activeTab === "View_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("View_Budget_expense")}
                  style={{ cursor: "pointer" }}
                >
                  View Invoices
                </a>
              </nav>

              {/* Tab Content */}
              <div className="card-body tab-content h-100">
                {/* create_Budget_expense Tab */}
                {activeTab === "create_Budget_expense" && (
                  <div className="row">
                    <InvoiceCreate function={getInvoices} />
                  </div>
                )}
                {/* View_Budget_expense Tab */}
                {activeTab === "View_Budget_expense" && (
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "-5px", marginLeft: "-5px" }}
                    >
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}
                      >
                        <div
                          className="card mb-1"
                          style={{ borderRadius: ".55rem" }}
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 col-xl-12 mb-0">
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="col-lg-9 col-md-9">
                                    <div className="row">
                                      <div className="form-group col-md-3 col-lg-3 mt-1">
                                        <b>Select Project:</b>
                                        <br />
                                        <Select
                                          onChange={(e) => setprojectID(e.id)}
                                          getOptionLabel={(option) =>
                                            option.project_name
                                          }
                                          getOptionValue={(option) => option.id}
                                          isSearchable
                                          options={projects}
                                          value={
                                            projectID
                                              ? Array.isArray(projects) &&
                                                projects.find(
                                                  (value) =>
                                                    value.id === projectID
                                                )
                                              : ""
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-md-3 col-lg-3 mt-1">
                                        <b>Select Client:</b>
                                        <br />
                                        <Select
                                          onChange={(e) => setClientID(e.id)}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) => option.id}
                                          isSearchable
                                          options={clients}
                                          value={
                                            clientID
                                              ? Array.isArray(clients) &&
                                                clients.find(
                                                  (value) =>
                                                    value.id === clientID
                                                )
                                              : ""
                                          }
                                        />
                                      </div>

                                      <div className="col-md-2">
                                        <b>From:</b>
                                        <input
                                          type="date"
                                          value={start_date}
                                          onChange={(e) =>
                                            setStart_date(e.target.value)
                                          }
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-md-2">
                                        <b>To:</b>
                                        <input
                                          type="date"
                                          value={to_date}
                                          onChange={(e) =>
                                            setTo_date(e.target.value)
                                          }
                                          className="form-control"
                                        />
                                      </div>

                                      <div className="form-group col-lg-2 col-md-2 mt-4">
                                        <div className="input-group-append">
                                          <button
                                            type="submit"
                                            onClick={setFetch_data}
                                            disabled={loading}
                                            style={{ borderRadius: "5px" }}
                                            className="btn ripple btn-primary btn-with-icon"
                                          >
                                            <i class="fa-brands fa-searchengin"></i>
                                            Get Data
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 col-md-2">
                                    <div className="row">
                                      <div className="form-group col-lg-12 col-md-12 mt-4">
                                        <div className="input-group-append">
                                          <button
                                            style={{ borderRadius: "5px" }}
                                            onClick={(e) =>
                                              handleExportToExcel(e)
                                            }
                                            className="btn ripple btn-success btn-with-icon"
                                          >
                                            <i class="fa-solid fa-file-csv"></i>
                                            Export to Excel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  Row */}
                    <div class="col-lg-12">
                      <div class="card custom-card">
                        <div class="card-body">
                          <div>
                            <h2 class="mb-1 ">Invoices</h2>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-striped mg-b-0">
                              <thead>
                                <tr>
                                  <th>NO.</th>
                                  <th>Date</th>
                                  <th>INVOICE no.</th>
                                  <th>Client</th>
                                  <th>project_name</th>
                                  <th>ref_no</th>
                                  <th>lpo_no</th>
                                  <th>Invoice Amount</th>
                                  <th>V.A.T</th>
                                  <th>Invoice Total</th>
                                  <th>Amount Due</th>
                                  <th>Created_by</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>

                              <tbody>
                                {Array.isArray(invoices) &&
                                  invoices.map((item, key) => (
                                    <>
                                      <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{item.date}</td>

                                        <td>
                                          {item.Invo_no}
                                          <br />
                                          <button
                                            onClick={(e) =>
                                              handleInoviceView(e, item.id)
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-warning btn-sm"
                                          >
                                            <i class="fa-regular fa-eye"></i>
                                            &nbsp; view
                                          </button>
                                        </td>
                                        <td>{item.client_name}</td>
                                        <td>{item.project_name}</td>
                                        <td>{item.ref_no}</td>
                                        <td>{item.lpo_no}</td>
                                        <td>
                                          {item.amount_crude.amount_crude_c}
                                        </td>
                                        <td>{item.vat.vat_c}</td>
                                        <td>
                                          {item.Amount_vated.Amount_vated_c}
                                        </td>
                                        <td>{item.amount_due.amount_due_c}</td>
                                        <td>{item.created_by.name}</td>
                                        <td>
                                          <button
                                            style={{
                                              borderRadius: "10px",
                                              fontSize: "14px",
                                              height: "10px",
                                              margin: "2px",
                                              color: "white",
                                              background:
                                                "linear-gradient(to right,rgb(245, 24, 24),rgb(186, 102, 12))",
                                            }}
                                            onClick={(e) =>
                                              DeleteTransaction(e, item.id)
                                            }
                                            className="btn ripple btn-outline-success btn-with-icon"
                                            to="#"
                                          >
                                            <i class="fa-solid fa-trash-can-arrow-up"></i>
                                            &nbsp;
                                            <span className="sidemenu-label">
                                              {/* delete */}
                                            </span>
                                          </button>{" "}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                            <nav
                              className="mt-3"
                              style={{ marginLeft: "30px" }}
                            >
                              <ul className="pagination ">
                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setPreviousPageNumber}
                                  >
                                    Prev
                                  </button>
                                </li>

                                {Array.isArray(meta) &&
                                  meta.map((item) =>
                                    page === item ? (
                                      <li className="page-item active">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0);"
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={(e) =>
                                            setPageNumber(e, item)
                                          }
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    )
                                  )}

                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setNextPageNumber}
                                  >
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Row */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default InvoicesPage;
