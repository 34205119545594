import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";

function ProjectBudgetExpenseReport(props) {
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [projectID, setProjectID] = useState("");
  const [projects, setProjects] = useState([]);
  const [project_details, setProject_details] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [totals, setTotals] = useState({});
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTo_date(today);
  }, []);
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      const allOption = { id: "", project_name: "All Projects" };
      setProjects([allOption, ...server_response.details]);
    } else {
      setProjects([{ id: "", project_name: "All Projects" }]);
    }
  };

  useEffect(() => {
    getProjects();
    handleAdd();
  }, []);

  const handleAdd = async () => {
    // e.preventDefault();
    // if (
    //   start_date.trim() === "" ||
    //   to_date.trim() === "" ||
    //   projectID.trim() === ""
    // ) {
    //   toast.error("Please complete all fields.");
    // } else {
    setLoading(true);

    var data = {
      to_date: to_date,
      start_date: start_date,
      projectID: projectID,
    };
    setLoading(true);
    const server_response = await ajaxAccounting.getprojectBudgectExpense(data);
    setLoading(false);

    setLoading(false);
    if (server_response.status === "OK") {
      setProject_details(server_response.details.project_name);
      setTransactions(server_response.details.ledgersGroups);
      setTotals(server_response.details.totals);
    } else {
      setProject_details("");
      setTransactions([]);
      setTotals({});
    }
    // }
  };

  return (
    <div>
      <AppContainer title="Budget Expenses">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="card-body">
                <Toaster />

                <div className="row">
                  <div className="col-md-3">
                    <b>Select Project:</b>
                    <Select
                      onChange={(e) => setProjectID(e.id)}
                      getOptionLabel={(option) => option.project_name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      options={projects}
                      value={
                        Array.isArray(projects) &&
                        projects.find((value) => value.id === projectID)
                      }
                    />
                  </div>
                  <div className="col-md-3">
                    <b>From:</b>
                    <input
                      type="date"
                      value={start_date}
                      onChange={(e) => setStart_date(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-3">
                    <b>To:</b>
                    <input
                      type="date"
                      value={to_date}
                      onChange={(e) => setTo_date(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-3 mt-4">
                    <button
                      type="submit"
                      disabled={loading}
                      onClick={handleAdd}
                      className="btn btn-primary"
                    >
                      Get Report
                    </button>
                  </div>
                </div>

                <div className="col-lg-12">
                  {project_details && (
                    <>
                      <h1 className="text-center mt-4">{project_details}</h1>
                      <h4 className="text-center"> Project Budget Report</h4>
                      <div className="row mt-4">
                        {Array.isArray(transactions) &&
                          transactions.map((transaction) => (
                            <div
                              style={{
                                border: "orange 3px groove",
                                padding: "10px",
                              }}
                              key={transaction.ledger_groupID}
                              className="col-lg-12 mb-4"
                            >
                              <h6 className="text-center card-title">
                                <u style={{ height: "3px" }}>
                                  {transaction.ledgerGroup_name}
                                </u>
                              </h6>

                              <div className="table-responsive">
                                <table className="table table-striped table-bordered">
                                  {loading && <Loader />}
                                  <thead>
                                    <tr>
                                      <th>#</th>
                                      <th>Ledger Name</th>
                                      <th>Amount Allocated</th>
                                      <th>Amount Used</th>
                                      <th>Balance</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {transaction.transactions.map(
                                      (ledger, i) => (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>{ledger.ledger_name}</td>
                                          <td>
                                            {ledger.sum_planned
                                              ? ledger.sum_planned.sum_planned_c
                                              : 0}
                                          </td>
                                          <td>
                                            {ledger.sum_debited.sum_debited_c}
                                          </td>

                                          <td>
                                            {ledger.amount_left.amount_left_c}
                                          </td>
                                          <td>
                                            {ledger.amount_left.amount_left >
                                            0 ? (
                                              <span className="text-success">
                                                In Budget
                                              </span>
                                            ) : (
                                              <span className="text-danger">
                                                Over Budget
                                              </span>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <b>Total Allocated:</b> &nbsp; &nbsp;
                                        {
                                          transaction.subtotal_allocated
                                            .subtotal_allocated_c
                                        }
                                      </td>{" "}
                                      <td>
                                        <b>Total Spent:</b> &nbsp; &nbsp;
                                        {
                                          transaction.subtotal_spent
                                            .subtotal_spent_c
                                        }
                                      </td>
                                      <td>
                                        <b>Total Balance :</b> &nbsp; &nbsp;
                                        {
                                          transaction.subtotal_balance
                                            .subtotal_balance_c
                                        }
                                      </td>
                                      <td>
                                        {transaction.subtotal_balance
                                          .subtotal_balance > 0 ? (
                                          <span className="text-success">
                                            In Budget
                                          </span>
                                        ) : (
                                          <span className="text-danger">
                                            Over Budget
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                      </div>
                      {/* Display Final Totals */}
                      <hr style={{ borderBottom: "2px dotted black" }} />
                      <div className="col-lg-12">
                        <h6 className="text-center">
                          <u>Total Buget Status</u>
                        </h6>
                        <table className="table table-striped table-bordered">
                          <tr>
                            <td>
                              <b>Total Allocated:</b>
                              {totals.subtotal_allocated.subtotal_allocated_c}
                            </td>
                            <td>
                              <b>Total Spent:</b>{" "}
                              {totals.total_spent.total_spent_c}
                            </td>
                            <td>
                              <b>Total Balance:</b>{" "}
                              {totals.total_balance.total_balance_c}
                            </td>
                            <td>
                              <b>Status:</b>
                              {totals.total_balance.total_balance > 0 ? (
                                <span className="text-success">In Budget</span>
                              ) : (
                                <span className="text-danger">Over Budget</span>
                              )}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default ProjectBudgetExpenseReport;
