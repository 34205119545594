import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Alert from "../Components/Common/Alert";
import ajaxUser from "../util/remote/ajaxUser";
import Loader from "../Components/Common/Loader";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [info, setInfo] = useState("");

  const [loading, setLoading] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      setLoading(true);
      const server_response = await ajaxUser.loginUser(username, password);
      setLoading(false);

      if (server_response.status === "OK") {
        localStorage.setItem("projectm@user", server_response.details);
        navigate("/");
        window.location.reload();
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div className="page main-signin-wrapper">
      <img
        className="back-img"
        style={{ width: "100%", height: "100%", position: "fixed" }}
        src={`${process.env.PUBLIC_URL}/template/assets/images/project_login.png`}
      />

      <div className="row text-center pl-0 pr-0 ml-0 mr-0">
        <div className="col-lg-3 d-block mx-auto">
          <div
            className="card custom-card"
            style={{
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(12px)",
              borderRadius: "20px",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="text-center mb-1 mt-2">
              <i className="fa fa-home header-brand-img  theme-logos"></i>
              <img
                style={{ height: "70px" }}
                src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
                className="header-brand-img"
                alt="logo"
              />
              <img
                style={{ height: "70px" }}
                src={`${process.env.PUBLIC_URL}/template/assets/images/bericot_logo.png`}
                className="header-brand-img theme-logos"
                alt="logo"
              />
            </div>
            <div className="card-body">
              <h2 className="text-center text-light mb-2">Login</h2>
              {loading ? <Loader /> : info}
              <form onSubmit={loginUser}>
                <div className="form-group text-left text-light mt-2">
                  <label>Username</label>
                  <input
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="form-control"
                    placeholder="Enter your email"
                    type="text"
                  />
                </div>
                <div className="form-group text-light text-left">
                  <label>Password</label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control text-dark"
                    placeholder="Enter your password"
                    type="password"
                  />
                </div>
                <button
                  type="submit"
                  className="btn ripple btn-main-primary btn-block"
                >
                  Sign In
                </button>
              </form>
              <div className="mt-3 text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
