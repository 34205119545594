import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxClient from "../../util/remote/ajaxClient";

const UpdateLedgerType = (props) => {
  const [name, setName] = useState(props.data.name);
  const [entry, setEntry] = useState(props.data.entry_type);
  const [main_Account_ID, setMain_Account_ID] = useState(
    props.data.main_groupID
  );
  const [ID, setID] = useState(props.data.id);

  const [loading, setLoading] = useState(false);
  // "id": "7",
  // "name": "CURRENT ASSETS",
  // "entry_type": "Debit",
  // "main_groupID": "1",
  // "main_account": "Balance sheet"
  const [mainACs, setMainAcs] = useState("");

  const getMainAccounts = async () => {
    const server_response = await ajaxAccounting.getMainAccounts();
    if (server_response.status === "OK") {
      setMainAcs(server_response.details);
    } else {
      setMainAcs("404");
    }
  };
  useEffect(() => {
    getMainAccounts();
  }, []);

  const handleSubmission = async (e, controls) => {
    e.preventDefault();
    // Form validation
    if (
      name.trim() === "" ||
      entry.trim() === "" ||
      main_Account_ID.trim() === ""
    ) {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);
      try {
        var data = {
          groupID: ID,
          ledgerTypes: name,
          entry_type: entry,
          main_Account: main_Account_ID,
        };
        const server_response = await ajaxAccounting.UpdateLedgerGroup(data);
        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          props.function();
          controls.close();
        } else {
          toast.error(server_response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleSubmission(e, controls)}
          >
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Ledger"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}
    >
      <div className="col-ld-12">
        <div className="row">
          <div className="mb-4 col-md-12">
            <label htmlFor="">
              Account Name:<span className="tx-danger">*</span>
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              placeholder="enter Account group name"
            />
          </div>

          <div className="mb-4 col-md-12">
            <label htmlFor="">
              Entry Type:<span className="tx-danger">*</span>
            </label>
            <select
              value={entry}
              onChange={(e) => setEntry(e.target.value)}
              class="form-control"
            >
              <option selected disabled value="">
                --select--entryType--
              </option>
              <option value="credit">credit</option>
              <option value="Debit">Debit</option>
            </select>
          </div>

          <div className="mb-4 col-md-12">
            <label htmlFor="">
              select Main Group:<span className="tx-danger">*</span>
            </label>
            <Select
              onChange={(e) => setMain_Account_ID(e.id)}
              getOptionLabel={(option) => option.account_name}
              getOptionValue={(option) => option.id}
              isSearchable
              options={Array.isArray(mainACs) ? mainACs : []}
              value={
                main_Account_ID
                  ? Array.isArray(mainACs) &&
                    mainACs.find((value) => value.id === main_Account_ID)
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default UpdateLedgerType;
