import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

function LedgerTrialBalanceReport(props) {
  const { ledgerID, projectID, start_date, end_date } = useParams();

  // "opening_blance": {
  //   "total": -121303795,
  //   "total_c": "-121,303,795",
  //   "total_p": "-121,303,795"
  // },
  // "closing_Balance": {
  //   "total": -128343795,
  //   "total_c": "-128,343,795",
  //   "total_p": "-128,343,795"
  // },
  // "total_credit": {
  //   "total": 26040000,
  //   "total_c": "26,040,000",
  //   "total_p": "26,040,000"
  // },
  // "total_debit": {
  //   "total": 19000000,
  //   "total_c": "19,000,000",
  //   "total_p": "19,000,000"
  // },

  const projectIDD = projectID === "NULL" ? "" : projectID;
  const [project_details, setProject_details] = useState("");
  const [ledgerID_debited, setLedgerID_debited] = useState("");
  const [transactions, setTransactions] = useState("");
  const [total, setTotal] = useState("");
  const [opening_balance, setOpening_balance] = useState("");
  const [closing_Balance, setClosing_Balance] = useState("");
  const [total_credit, setTotal_credit] = useState("");
  const [total_debit, setTotal_debit] = useState("");
  const [dataStatus, setDataStatus] = useState("");

  const getPaymentVoucher = async () => {
    var data = {
      LedgerID: ledgerID,
      projectID: projectIDD,
      start_date: start_date === "NULL" ? "" : start_date,
      to_date: end_date === "NULL" ? "" : end_date,
    };
    const server_response = await ajaxAccounting.getLedgerTrialStatement(data);
    if (server_response.status === "OK") {
      setTransactions(server_response.details.data_list);
      setProject_details(server_response.details.project);
      setLedgerID_debited(server_response.details.ledgerID_debited);
      setTotal(server_response.details.ledger_tot);
      setOpening_balance(server_response.details.opening_balance);
      setClosing_Balance(server_response.details.closing_Balance);
      setTotal_credit(server_response.details.total_credit);
      setTotal_debit(server_response.details.total_debit);
    } else {
      setDataStatus("404");
    }
  };

  // Printing functionality
  const handlePrint = (e) => {
    e.preventDefault();

    const companyHeader = `
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
      
        <div style="width: 80%; line-height: 6px; text-align: center;">
          <h3>BERICOT AFRICA LIMITED</h3>
          <p style="font-size: 14px;">P.O.BOX 28775 KAMPALA</p>
          <p style="font-size: 14px;">PLOT 79B MAKAMBA ROAD</p>
          <p style="font-size: 14px;">TEL: +256701666160</p>
          <p style="font-size: 14px;">EMAIL: info@bericot.africa</p>
         
        </div>
          <div style="width: 20%;">
          <img
            style="position: absolute; height: 100px; width: 200px;"
            src="${process.env.PUBLIC_URL}/template/assets/images/bricote_logo.png"
            alt="Logo"
          />
        </div>
      </div>
      <hr style="border: 1px solid black; margin-bottom: 20px;" />
    `;

    const printContents = document.getElementById("printablediv").innerHTML;
    const originalTitle = document.title;
    const newWindow = window.open("", "_blank");

    // Construct the printable content
    newWindow.document.open();
    newWindow.document.write(`
      <html>
        <head>
          <title>${originalTitle} - Print</title>
                    <style>
            /* Include the print-specific styles here */
            @media print {
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 0;
                width: 100%;
                height: auto;
              }

              .card-body {
                padding: 20px;
                width: 100%;
              }

              #printablediv {
                width: 100%;
                margin: 0;
                padding: 0;
              }

              img {
                width: 200px;
                height: auto;
              }

              h3, h4, h1 {
                text-align: center;
                font-size: 18px;
              }

              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
                font-size: 14px;
              }

              table th, table td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
              }

              table th {
                background-color: #f2f2f2;
              }

              .no-print {
                display: none;
              }

              .page-break {
                page-break-before: always;
              }

              .total-section td {
                font-weight: bold;
                text-align: right;
              }
            }
          </style>
        </head>
        <body style="font-family: Arial, sans-serif; margin: 0; padding: .5cm; width: 21cm; height: 29.7cm;">
          ${companyHeader}
          ${printContents}
          <script>
            // Ensure the image is loaded before printing
            document.querySelector('img').onload = () => {
              window.print();
            };

            // Close the window after printing or canceling
            window.onafterprint = () => {
              window.close();
            };
          </script>
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document to finish writing
  };

  useEffect(() => {
    getPaymentVoucher();
  }, []);
  return (
    <div>
      <AppContainer title="Budget Expenses">
        {/* Row */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div className="col-md-2 mt-4">
                  <button
                    style={{ borderRadius: "5px" }}
                    onClick={(e) => handlePrint(e, "12345")} // Provide folio number dynamically if necessary
                    className="btn btn-success"
                  >
                    Print Data
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12" id="printablediv">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h3 className="mb-1 text-center">
                    <b>{project_details}</b>
                  </h3>
                  <h3 className="mb-1 text-center">
                    <b>{ledgerID_debited}</b> Transaction Statement
                  </h3>
                  <h5 className="text-muted text-center">
                    Perion : From{" "}
                    <span style={{ color: "blue" }}>
                      {new Date(start_date).toLocaleDateString("en-GB")}{" "}
                    </span>
                    &nbsp;To{" "}
                    <span style={{ color: "blue" }}>
                      {new Date(end_date).toLocaleDateString("en-GB")}{" "}
                    </span>
                  </h5>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped table-bordered mt-5">
                    <thead>
                      <tr style={{ backgroundColor: "grey", height: "30px" }}>
                        <th>NO.</th>
                        {/* <th>Folio NO.</th> */}
                        <th>Date</th>
                        <th>reference NO.</th>
                        <th>Ledger Credited</th>
                        <th>Voucher Type</th>
                        {/* <th>Entry Type</th> */}
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Closing Balance</th>
                        {/* <th>Details</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>#</th>
                        <td>
                          {" "}
                          {new Date(start_date).toLocaleDateString(
                            "en-GB"
                          )}{" "}
                        </td>
                        <td>Opening Balance</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td>{opening_balance.total_c}</td>
                        {/* <td></td> */}
                      </tr>
                      {Array.isArray(transactions) &&
                        transactions.map((item, key) => (
                          <>
                            <tr key={key}>
                              <th>{key + 1}</th>
                              <td>{item.date}</td>
                              <td>{item.Folio_ref}</td>
                              <td>{item.ledgerID_credited}</td>
                              <td>{item.trans_type}</td>
                              {/* <td>{item.entry_type}</td> */}
                              <td>{item.debit.debit_c}</td>
                              <td>{item.credit.credit_c}</td>

                              <td>{item.balance.balance_c}</td>
                              {/* <td>{item.details}</td> */}
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped mt-2 table-bordered">
                    <tr>
                      <td>Total Debits</td>
                      <td>
                        <b>{total_debit.total_c}</b>
                      </td>
                      <td>Total Credits</td>
                      <td>
                        {" "}
                        <b>{total_credit.total_c}</b>
                      </td>
                      <td>closing Balance</td>
                      <td>
                        {" "}
                        <b>{closing_Balance.total_c}</b>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Row */}
      </AppContainer>
    </div>
  );
}

export default LedgerTrialBalanceReport;
