import { useEffect, useState } from "react";
import ajaxProduct from "../../util/remote/ajaxProduct";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";

const ListInventoryForClient = (props) => {
  const [product, setProduct] = useState(props.product);
  const [productName, setProductName] = useState(props.productName);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(props.client);
  const [clientName, setClientName] = useState(props.clientName);
  const [inventoryList, setInventoryList] = useState(false);

  const getInventory = async () => {
    setLoading(true);
    let data = {
      product_id: product,
      client: client,
      storeID: props.storeID,
    };
    const server_response = await ajaxProduct.fetchClientProductTransactions(
      data
    );
    setLoading(false);
    if (server_response.status === "OK") {
      setInventoryList(server_response.details);
    }
  };

  useEffect(() => {
    getInventory(product, client);
  }, [product, client]);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}
          >
            Close
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Inventory Transactions"
      id="model-list_transactions"
      size="md"
      footer={RenderFooter}
    >
      <div class="d-lg-flex">
        <h2 class="card-title mb-1">Product: {productName}</h2>
        <div class="ml-auto">
          <p class="mb-1">
            <span class="font-weight-bold">Client :</span> {clientName}
          </p>
        </div>
      </div>
      <hr class="mg-b-40" />
      <div className="table-responsive">
        {loading && <Loader />}

        <table className="table table-striped table-bordered">
          <thead>
            <th>Date</th>
            <th>Quantity In</th>
            <th>Quantity Out</th>
          </thead>
          <tbody>
            {!Array.isArray(inventoryList) && (
              <tr>No inventory transactions yet</tr>
            )}
            {Array.isArray(inventoryList) &&
              inventoryList.map((item, index) => (
                <tr key={index}>
                  <td>{item.date_added.long_date}</td>
                  <td>{item.quantity_in}</td>
                  <td>{item.quantity_out}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </SystemModal>
  );
};

export default ListInventoryForClient;
