import React, { useContext, useEffect, useState } from "react";
import ajaxProduct from "../../util/remote/ajaxProduct";
import ajaxStores from "../../util/remote/ajaxStores";
import AppContainer from "../../Components/Structure/AppContainer";
import Loader from "../../Components/Common/Loader";
import ClientContext from "../../Context/ClientContext";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";
import TableHeader from "../../Components/Common/TableHeader";
import ListInventoryForClient from "./ListInventoryForClient";
import useStateCallback from "../../util/customHooks/useStateCallback";
import { Link, useParams } from "react-router-dom";

function StoreInventory() {
  const { StoreID, store_name } = useParams();

  const [inventoryList, setInventoryList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState("");
  const [store_id, setStore_id] = useState(StoreID);
  const { clientList } = useContext(ClientContext);
  const [inventorySearch, setInventorySearch] = useState(false);
  const [modal, setModal] = useStateCallback(false);

  useEffect(() => {
    getProductInventory();
  }, [client, store_id]);

  const handleModal = (e, item) => {
    setModal(false, () =>
      setModal(
        <ListInventoryForClient
          product={item.product_id}
          productName={item.product_name}
          clientName={item.client_name}
          client={item.client_id}
          storeID={StoreID}
          isOpen={true}
        />
      )
    );
  };

  const getProductInventory = async () => {
    let data = {
      client: client,
      storeID: store_id,
    };
    setLoading(true);

    const server_response = await ajaxProduct.fetchInventoryPerClient(data);
    setLoading(false);

    if (server_response.status === "OK") {
      setInventoryList(server_response.details);
    } else {
      setInventoryList("");
    }
  };

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = `${store_name} inventory statement`; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description
    // Add a heading to the PDF
    const title = "Inventory Report";
    pdf.setFontSize(18); // Set font size for the title
    pdf.text(title, 40, 50); // Add title at position (x: 40, y: 50)

    // Define columns for the table
    const columns = [
      "Product Name",
      "Client Name",
      "Quantity In",
      "Quantity Out",
      "In Stock",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180, // Adjust startY to avoid overlapping with the title
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`inventory_data_${new Date().toLocaleDateString()}.pdf`);
  };

  const exportToExcel = () => {
    // Prepare data for export
    const data = (inventorySearch || inventoryList).map((item, index) => ({
      "Product Name": item.product_name,
      "Client Name": item.client_name,
      "Quantity In": item.qty_in.qty_in_c,
      "Quantity In": item.qty_out.qty_out_c,
    }));

    // Create a worksheet from your table data
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Inventory Data");

    // Generate a file name
    const fileName = "inventory_data.xlsx";

    // Save the file
    XLSX.writeFile(wb, fileName);
  };

  return (
    <AppContainer title="Inventory">
      <Toaster position="top-center" reverseOrder={false} />
      {modal}

      <div className="card custom-card">
        <div className="card-body">
          <TableHeader
            title={`${store_name} Inventory`}
            viewButton={
              <>
                <a
                  href="#"
                  onClick={exportToPDF}
                  className="btn btn-secondary btn-sm"
                  style={{ float: "right" }}
                >
                  Export to PDF
                </a>
                <a
                  href="#"
                  onClick={exportToExcel}
                  className="btn btn-primary btn-sm mr-2"
                  style={{ float: "right" }}
                >
                  Export to Excel
                </a>
              </>
            }
          />
          <div className="table-responsive">
            {loading && <Loader />}
            <table className="table table-striped table-bordered">
              <thead>
                <th>Product Name</th>
                <th>Client Name</th>
                <th>Store</th>
                <th>Quantity In</th>
                <th>Quantity Out</th>
                <th>closing balance</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {Array.isArray(inventoryList) ? (
                  inventoryList.map((item, key) => (
                    <tr key={key}>
                      <td>{item.product_name}</td>
                      <td>{item.client_name}</td>
                      <td>{item.store ?? "------"}</td>
                      <td>{item.qty_in.qty_in_c}</td>
                      <td>{item.qty_out.qty_out_c}</td>
                      <td>{item.balance.balance_c}</td>
                      <td>
                        <Link
                          className="btn btn-sm btn-info mr-2"
                          href="#"
                          onClick={(e) => handleModal(e, item)}
                        >
                          View Ins and Outs
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <p style={{ margin: "auto" }}>
                    No products found that match the selected Store.
                  </p>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default StoreInventory;
