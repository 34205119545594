import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import AuthContext from "../../Context/AuthContext";
import useStateCallback from "../../util/customHooks/useStateCallback";
import AddTransactionOnTransaction from "./AddTransactionOnTransaction";
import TransactionUpdater from "./TransactionUpdater";
import TransactionCatUpdater from "./TransactionCatUpdater";
import ajaxUser from "../../util/remote/ajaxUser";
import JournalPrinting from "./JournalPrinting";
import InventoryJournaltExportPdf from "./accountsExportModals/InventoryJournaltExportPdf";
import InventoryJournalExportexcel from "./accountsExportModals/InventoryJournalExportexcel";

function Journals(props) {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("create_Budget_expense");
  const [amount, setAmount] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [description, setDescription] = useState("");
  const [cost_item, setCost_item] = useState("");
  const [date, setDate] = useState("");
  const [reciever, setReciever] = useState("");
  const [employee, setEmployee] = useState("");
  const [trans_type, setTrans_type] = useState("journal");
  const [invoice_reff, setInvoice_reff] = useState("");
  const [Supplier_ivoice_reff, setSupplier_ivoice_reff] = useState("");
  const [voucher_no, setVoucher_no] = useState("");
  const { user, userId } = useContext(AuthContext);
  const [vatApplied, setVatApplied] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      projectID.trim() === "" ||
      LedgerID_credited.trim() === "" ||
      LedgerID_debited.trim() === "" ||
      amount.trim() === "" ||
      date.trim() === "" ||
      LedgerID_credited === LedgerID_debited // checks  if the ledgers selected are not the same
    ) {
      toast.error(
        "Please complete all fields and make sure the selected ledger are (s) are not the same"
      );
    } else {
      setLoading(true);
      var data = {
        folioID: "",
        amount: amount,
        projectID: projectID,
        LedgerID_debited: LedgerID_debited,
        LedgerID_credited: LedgerID_credited,
        cost_item: cost_item,
        date: date,
        reciever: reciever,
        employee: employee,
        created_by: userId,
        trans_type: trans_type,
        description: description,
        Supplier_ivoice_reff: Supplier_ivoice_reff,
        voucher_no: voucher_no,
        invoice_reff: invoice_reff,
        vatApplied: vatApplied,
      };

      const server_response = await ajaxAccounting.CreateJournal(data);
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        FetchTransactions();
        resetForm();
      } else {
        toast.error(server_response.message);
      }
    }
  };
  const resetForm = () => {
    setProjectID("");
    setAmount("");
    setCost_item("");
    setLedgerID_credited("");
    setLedgerID_debited("");
    setDescription("");
    setVatApplied(false);
  };

  const [employees, setEmployees] = useState("");
  const getEmployees = async () => {
    const server_response = await ajaxUser.getUsersMinimal_data();
    if (server_response.status === "OK") {
      setEmployees(server_response.details);
    } else {
      setEmployees("404");
    }
  };

  const [Debitledgers, setDebitledgers] = useState("");
  const getDebitlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    if (server_response.status === "OK") {
      setDebitledgers(server_response.details);
    } else {
      setDebitledgers("404");
    }
  };

  // ledgers fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
    getEmployees();
  }, []);

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };

  const [projectss, setProjectss] = useState("");
  const getProjectss = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      const allOption = { id: "", project_name: "All Projects" };
      setProjectss([allOption, ...server_response.details]);
    } else {
      setProjectss([{ id: "", project_name: "All Projects" }]);
    }
  };
  useEffect(() => {
    getProjectss();
  }, []);
  const [cost_allocations, setCost_allocations] = useState("");
  const getCost_Allocations = async () => {
    const server_response =
      await ajaxAccounting.getAllcostAllocations_min_data();
    if (server_response.status === "OK") {
      setCost_allocations(server_response.details);
    } else {
      setCost_allocations("404");
    }
  };
  useEffect(() => {
    getProjects();
    getCost_Allocations();
  }, []);
  // end posting works

  // fetch data of PaymentVoucher
  const setFetch_data = () => {
    FetchTransactions();
  };
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTo_date(today);
  }, []);

  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [projectIDD, setProjectIDD] = useState("");

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [PaymentVoucher, setPAymentVoucher] = useState("");
  const FetchTransactions = async () => {
    var data = {
      projectID: projectIDD,
      trans_type: trans_type,
      page: page,
      start_date: start_date,
      to_date: to_date,
    };
    setMeta("");
    setPAymentVoucher("");
    setLoading(true);
    const server_response = await ajaxAccounting.getPaymentVoucher(data);
    setLoading(false);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setPAymentVoucher(server_response.details.list);
    } else {
      setPAymentVoucher("404");
    }
  };

  useEffect(() => {
    FetchTransactions();
  }, [projectIDD, page]);

  // pagination handlers
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  // cost allocation updating
  const [voucherCat, setVoucherCat] = useStateCallback(false);
  const handvoucherCAtItem = (e, reference, item) => {
    e.preventDefault();
    setVoucherCat(false, () =>
      setVoucherCat(
        <TransactionCatUpdater
          isOpen={true}
          function={FetchTransactions}
          cost_allocations={cost_allocations}
          reference={reference}
          item={item}
        />
      )
    );
  };
  // cost allocation updating
  const [AddOnvoucher, setAddOnvoucher] = useStateCallback(false);
  const handleVoucherAddingOnVoucher = (e, data) => {
    e.preventDefault();
    setAddOnvoucher(false, () =>
      setAddOnvoucher(
        <AddTransactionOnTransaction
          isOpen={true}
          data={data}
          cost_items={cost_allocations}
          ledgers={Debitledgers}
          type={"journal"}
          function={FetchTransactions}
          projects={projects}
        />
      )
    );
  };
  const [printing, setPrinting] = useStateCallback(false);

  const handlePrinting = (e, folioID) => {
    e.preventDefault();
    setPrinting(false, () =>
      setPrinting(
        <JournalPrinting isOpen={true} folioID={folioID} type={"journal"} />
      )
    );
  };
  const [UpdateVoucher, setUpdateVoucher] = useStateCallback(false);

  const handleVoucherUpdate = (e, data) => {
    e.preventDefault();
    setUpdateVoucher(false, () =>
      setUpdateVoucher(
        <TransactionUpdater
          isOpen={true}
          data={data}
          employees={employees}
          function={FetchTransactions}
        />
      )
    );
  };

  /**
   * deleting transaction
   */

  const DeleteTransaction = async (e, reff) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to Delete this item ?"
    );

    if (isConfirmed) {
      var dataa = {
        reff: reff,
      };
      const server_response = await ajaxAccounting.DeleteJournalTransaction(
        dataa
      );

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        FetchTransactions();
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const [export_to_excel, setExport_to_excel] = useStateCallback(false);
  const handleExportToExcel = (e) => {
    e.preventDefault();
    setExport_to_excel(false, () =>
      setExport_to_excel(
        <InventoryJournalExportexcel
          trans_type={trans_type}
          projectID={projectIDD}
        />
      )
    );
  };
  const [export_to_Pfd, setExport_to_Pfd] = useStateCallback(false);
  const handleExportToPDF = (e) => {
    e.preventDefault();
    setExport_to_Pfd(false, () =>
      setExport_to_Pfd(
        <InventoryJournaltExportPdf
          trans_type={trans_type}
          projectID={projectIDD}
        />
      )
    );
  };

  return (
    <div>
      <AppContainer title="Accounting/journals">
        <div className="row">
          {voucherCat}
          {printing}
          {UpdateVoucher}
          {AddOnvoucher}
          {export_to_excel}
          {export_to_Pfd}
          <Toaster />
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              {/* Tabs navigation */}
              <nav className="nav main-nav-line">
                <a
                  className={`nav-link ${
                    activeTab === "create_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("create_Budget_expense")}
                  style={{ cursor: "pointer" }}
                >
                  Add Journals
                </a>
                <a
                  className={`nav-link ${
                    activeTab === "View_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("View_Budget_expense")}
                  style={{ cursor: "pointer" }}
                >
                  View Journals
                </a>
              </nav>

              {/* Tab Content */}
              <div className="card-body tab-content h-100">
                {/* create_Budget_expense Tab */}
                {activeTab === "create_Budget_expense" && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <div
                            className="card custom-card"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-4">
                                  Add Journals
                                </h6>
                              </div>

                              <form
                                onSubmit={(e) => handleAdd(e)}
                                method="post"
                              >
                                <div className="row">
                                  <input
                                    type="hidden"
                                    value={trans_type}
                                    onChange={(e) =>
                                      setTrans_type(e.target.value)
                                    }
                                    className="form-control"
                                  />
                                  <div className="mb-4 col-md-3">
                                    <label htmlFor="">
                                      select project
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                      onChange={(e) => setProjectID(e.id)}
                                      getOptionLabel={(option) =>
                                        option.project_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(projects) ? projects : []
                                      }
                                      value={
                                        projectID
                                          ? Array.isArray(projects) &&
                                            projects.find(
                                              (value) => value.id === projectID
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-4">
                                    <label htmlFor="">
                                      Select Account To Debit
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                      onChange={(e) =>
                                        setLedgerID_debited(e.id)
                                      }
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(Debitledgers)
                                          ? Debitledgers
                                          : []
                                      }
                                      value={
                                        LedgerID_debited
                                          ? Array.isArray(Debitledgers) &&
                                            Debitledgers.find(
                                              (value) =>
                                                value.id === LedgerID_debited
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-4">
                                    <label htmlFor="">
                                      Select Account To Credit
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                      onChange={(e) =>
                                        setLedgerID_credited(e.id)
                                      }
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(Debitledgers)
                                          ? Debitledgers
                                          : []
                                      }
                                      value={
                                        LedgerID_credited
                                          ? Array.isArray(Debitledgers) &&
                                            Debitledgers.find(
                                              (value) =>
                                                value.id === LedgerID_credited
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-4">
                                    <label htmlFor="">
                                      cost allocation center
                                    </label>
                                    <Select
                                      onChange={(e) => setCost_item(e.id)}
                                      getOptionLabel={(option) => option.item}
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={
                                        Array.isArray(cost_allocations)
                                          ? cost_allocations
                                          : [""]
                                      }
                                      value={
                                        cost_item
                                          ? Array.isArray(cost_allocations) &&
                                            cost_allocations.find(
                                              (value) => value.id === cost_item
                                            )
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="mb-4 col-md-4">
                                    <label htmlFor="">
                                      Amount
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={amount}
                                      onChange={(e) =>
                                        setAmount(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-4">
                                    <label htmlFor="">
                                      Supplier_ivoice_reff
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={Supplier_ivoice_reff}
                                      onChange={(e) =>
                                        setSupplier_ivoice_reff(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      Reciever
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      value={reciever}
                                      onChange={(e) =>
                                        setReciever(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-6">
                                    <label htmlFor="">
                                      Date
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="date"
                                      value={date}
                                      onChange={(e) => setDate(e.target.value)}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-4 col-md-12">
                                    <label htmlFor="">
                                      Description
                                      <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <textarea
                                      value={description}
                                      onChange={(e) =>
                                        setDescription(e.target.value)
                                      }
                                      className="form-control"
                                    ></textarea>
                                  </div>
                                </div>

                                {/* VAT Checkbox */}
                                <div className="mb-4 col-md-12">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={vatApplied}
                                      style={{ accentColor: "green" }} // Changes checkbox color
                                      onChange={(e) =>
                                        setVatApplied(e.target.checked)
                                      }
                                      className="mr-2"
                                    />
                                    Apply VAT (18%)
                                  </label>
                                </div>

                                <div className="col-md-12 col-lg-12">
                                  <input
                                    disabled={loading}
                                    type="submit"
                                    style={{ borderRadius: "10px" }}
                                    className="btn btn-success col-lg-12"
                                    value="Save Payment Voucher"
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* View_Budget_expense Tab */}
                {activeTab === "View_Budget_expense" && (
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "-5px", marginLeft: "-5px" }}
                    >
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}
                      >
                        <div
                          className="card mb-1"
                          style={{ borderRadius: ".55rem" }}
                        >
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 col-xl-12 mb-0">
                                <div
                                  className=""
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div className="col-lg-8 col-md-8">
                                    <div className="row">
                                      <div className="form-group col-lg-3 col-md-3 mt-2">
                                        <b>Select Project:</b>
                                        <br />
                                        <Select
                                          onChange={(e) => setProjectIDD(e.id)}
                                          getOptionLabel={(option) =>
                                            option.project_name
                                          }
                                          getOptionValue={(option) => option.id}
                                          isSearchable
                                          options={projectss}
                                          value={
                                            Array.isArray(projectss) &&
                                            projectss.find(
                                              (value) => value.id === projectIDD
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <b>From:</b>
                                        <input
                                          type="date"
                                          value={start_date}
                                          onChange={(e) =>
                                            setStart_date(e.target.value)
                                          }
                                          className="form-control"
                                        />
                                      </div>
                                      <div className="col-md-3">
                                        <b>To:</b>
                                        <input
                                          type="date"
                                          value={to_date}
                                          onChange={(e) =>
                                            setTo_date(e.target.value)
                                          }
                                          className="form-control"
                                        />
                                      </div>

                                      <div className="form-group col-lg-3 col-md-3 mt-4">
                                        <div className="input-group-append">
                                          <button
                                            type="submit"
                                            onClick={setFetch_data}
                                            disabled={loading}
                                            style={{ borderRadius: "5px" }}
                                            className="btn ripple btn-primary btn-with-icon"
                                          >
                                            <i class="fa-brands fa-searchengin"></i>
                                            Get Data
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4 col-md-4">
                                    <div className="row">
                                      <div className="form-group col-lg-6 col-md-6 mt-4">
                                        <div className="input-group-append">
                                          <button
                                            style={{ borderRadius: "5px" }}
                                            onClick={(e) =>
                                              handleExportToExcel(e)
                                            }
                                            className="btn ripple btn-success btn-with-icon"
                                          >
                                            <i class="fa-solid fa-file-csv"></i>
                                            Export to Excel
                                          </button>
                                        </div>
                                      </div>
                                      <div className="form-group col-lg-6 col-md-6 mt-4">
                                        <div className="input-group-append">
                                          <button
                                            style={{ borderRadius: "5px" }}
                                            onClick={handleExportToPDF}
                                            className="btn ripple btn-secondary btn-with-icon"
                                          >
                                            <i class="fa-solid fa-file-pdf"></i>
                                            Export to PDF
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {/* row */}
                      <div className="card custom-card">
                        <div className="card-body">
                          <div>
                            <h6 className="card-title mb-4">Journals View</h6>
                          </div>
                          <div className="table-responsive">
                            {loading && <Loader />}
                            <table
                              className="table table-striped table-bordered"
                              style={{ border: "orange 3px groove" }}
                            >
                              <thead>
                                <th>#</th>
                                <th>reff</th> <th>Date</th>
                                <th>Project</th>
                                <th>ledger Debited</th>
                                <th>ledger Credited</th>
                                <th>Amount</th>
                                <th>details</th>
                                <th>
                                  cost Allocation <br />
                                  Item
                                </th>
                                <th>Reciever</th>
                                <th>Created By</th>
                                <th>Action</th>
                              </thead>
                              <tbody>
                                {Array.isArray(PaymentVoucher) &&
                                  PaymentVoucher.map((item, key) => (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <th>{item.folio_reff}</th>
                                      <td>{item.date.short_date}</td>
                                      <td>{item.projectID.project_name}</td>
                                      <td>
                                        {item.ledgerID_debited.Ledger_name}
                                        <br />
                                        <span>
                                          <button
                                            onClick={(e) =>
                                              handleVoucherUpdate(e, item)
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-warning btn-sm"
                                          >
                                            <i class="fa-solid fa-pen-to-square"></i>
                                            &nbsp; Update Transaction
                                          </button>
                                          <br />
                                          {/* <button
                                            onClick={(e) =>
                                              handleVoucherAddingOnVoucher(
                                                e,
                                                item
                                              )
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-danger btn-sm"
                                          >
                                            <i class="fa-solid fa-plus"></i>
                                            &nbsp; Add on A Voucher
                                          </button> */}
                                          <button
                                            onClick={(e) =>
                                              handlePrinting(e, item.folioID)
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-secondary btn-sm"
                                          >
                                            <i class="fa-solid fa-print"></i>
                                            &nbsp; print
                                          </button>
                                        </span>
                                      </td>
                                      <td>
                                        {item.ledgerID_credited.Ledger_name}
                                      </td>
                                      <td>{item.amount.amount_p}</td>
                                      <td
                                        style={{
                                          width: "200px",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {item.description}
                                      </td>
                                      <td>
                                        {item.catID ? (
                                          item.catID.item
                                        ) : (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                handvoucherCAtItem(
                                                  e,
                                                  item.reference,
                                                  item
                                                )
                                              }
                                              style={{ borderRadius: "5px" }}
                                              className="btn ripple btn-primary btn-sm"
                                            >
                                              <i class="fa-solid fa-plus"></i>
                                              Add CAC
                                            </button>
                                          </>
                                        )}
                                      </td>
                                      <td>{item.reciever}</td>
                                      <td>{item.created_by.name}</td>
                                      <td>
                                        <button
                                          style={{
                                            borderRadius: "10px",
                                            fontSize: "14px",
                                            height: "10px",
                                            margin: "2px",
                                            color: "white",
                                            background:
                                              "linear-gradient(to right,rgb(245, 24, 24),rgb(186, 102, 12))",
                                          }}
                                          onClick={(e) =>
                                            DeleteTransaction(e, item.reference)
                                          }
                                          className="btn ripple btn-outline-success btn-with-icon"
                                          to="#"
                                        >
                                          <i class="fa-solid fa-trash-can-arrow-up"></i>
                                          &nbsp;
                                          <span className="sidemenu-label">
                                            delete
                                          </span>
                                        </button>{" "}
                                      </td>
                                    </tr>
                                  ))}

                                {loading && <Loader />}
                                {PaymentVoucher === "404" && (
                                  <>
                                    <tr>
                                      <td colSpan={10}>
                                        <p className="text-center text-info">
                                          No data found in the system
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <nav
                              className="mt-3"
                              style={{ marginLeft: "30px" }}
                            >
                              <ul className="pagination ">
                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setPreviousPageNumber}
                                  >
                                    Prev
                                  </button>
                                </li>

                                {Array.isArray(meta) &&
                                  meta.map((item) =>
                                    page === item ? (
                                      <li className="page-item active">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0);"
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={(e) =>
                                            setPageNumber(e, item)
                                          }
                                        >
                                          {item}
                                        </a>
                                      </li>
                                    )
                                  )}

                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setNextPageNumber}
                                  >
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end row  */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default Journals;
