import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import useStateCallback from "../../util/customHooks/useStateCallback";
import AllTransactionsExportPdf from "./accountsExportModals/AllTransactionsExportPdf";
import AllTransactionsExportexcel from "./accountsExportModals/AllTransactionsExportexcel";

function AllTransactionsView(props) {
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [projectID, setProjectID] = useState("");
  const [ledgerID, setLedgerID] = useState("");
  const [projects, setProjects] = useState([]);
  const [ledgers, setLedgers] = useState([]);
  const [Transactions, setTransactions] = useState([]);
  const [meta, setMeta] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTo_date(today);
  }, []);

  useEffect(() => {
    getTransactions(); // Fetch initial transactions
  }, [page, start_date, to_date, projectID, ledgerID]); // Add dependencies to fetch when any of these change

  // Fetch Projects
  const getProjects = async () => {
    try {
      const server_response =
        await ajaxProject.getProjectsMinnimalDataCliented();
      if (server_response.status === "OK") {
        const allOption = { id: "", project_name: "All Projects" };
        setProjects([allOption, ...server_response.details]);
      } else {
        setProjects([{ id: "", project_name: "All Projects" }]);
        toast.error("Failed to load projects.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching projects.");
    }
  };

  // Fetch Ledgers and include "All Ledgers"
  const getCreditlegders = async () => {
    try {
      const server_response = await ajaxAccounting.getALlLedgersMinData();
      if (server_response.status === "OK") {
        const allLedgersOption = { id: "", name: "All Ledgers" };
        setLedgers([allLedgersOption, ...server_response.details]);
      } else {
        setLedgers([{ id: "", name: "All Ledgers" }]);
        toast.error("Failed to load ledgers.");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching ledgers.");
    }
  };

  // Fetch Transactions
  const getTransactions = async () => {
    setLoading(true);
    const data = {
      page: page,
      to_date: to_date,
      start_date: start_date,
      projectID: projectID,
      ledgerID: ledgerID,
    };
    setMeta([]);
    setTransactions([]);
    try {
      const server_response = await ajaxAccounting.getTransactionsAll(data);
      setLoading(false);
      if (server_response.status === "OK") {
        setMeta(server_response.details.meta.list_of_pages);
        setTransactions(server_response.details.list);
      } else {
        setTransactions([]);
        toast.error("No transactions found.");
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Error fetching transactions.");
    }
  };

  useEffect(() => {
    getProjects();
    getCreditlegders();
  }, []);

  // Pagination handlers
  const setNextPageNumber = () => {
    if (page < meta.length) {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const setPageNumber = (e, item) => {
    setPage(item);
  };

  const [export_to_excel, setExport_to_excel] = useStateCallback(false);
  const handleExportToExcel = (e) => {
    e.preventDefault();
    setExport_to_excel(false, () =>
      setExport_to_excel(
        <AllTransactionsExportexcel trans_type={""} projectID={projectID} />
      )
    );
  };

  const [export_to_Pfd, setExport_to_Pfd] = useStateCallback(false);
  const handleExportToPDF = (e) => {
    e.preventDefault();
    setExport_to_Pfd(false, () =>
      setExport_to_Pfd(
        <AllTransactionsExportPdf trans_type={""} projectID={projectID} />
      )
    );
  };

  return (
    <div>
      {export_to_excel}
      {export_to_Pfd}
      <AppContainer title="All Transactions View">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="card-body">
                <Toaster />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    getTransactions();
                  }}
                >
                  <div className="row">
                    <div className="col-md-3 col-lg-3">
                      <b>Select Project:</b>
                      <Select
                        onChange={(e) => setProjectID(e.id)}
                        getOptionLabel={(option) => option.project_name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={projects}
                        value={
                          Array.isArray(projects) &&
                          projects.find((value) => value.id === projectID)
                        }
                      />
                    </div>
                    <div className="col-md-2">
                      <b>
                        From <span style={{ color: "red" }}>*</span>:
                      </b>
                      <input
                        type="date"
                        value={start_date}
                        onChange={(e) => setStart_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-2">
                      <b>
                        To <span style={{ color: "red" }}>*</span>:
                      </b>
                      <input
                        type="date"
                        value={to_date}
                        onChange={(e) => setTo_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-3 col-md-3">
                      <b> Select Ledger :</b>
                      <Select
                        onChange={(e) => setLedgerID(e.id)}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={Array.isArray(ledgers) ? ledgers : []}
                        value={
                          ledgerID
                            ? Array.isArray(ledgers) &&
                              ledgers.find((value) => value.id === ledgerID)
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-2 col-lg-2 mt-4">
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary"
                      >
                        {loading ? "Loading..." : "Get Report"}
                      </button>
                    </div>
                  </div>
                </form>

                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-6">
                              <h2>All Transactions </h2>
                            </div>
                            <div className="col-lg-4">
                              <div className="row">
                                <div className="form-group col-lg-6 col-md-6 mt-2">
                                  <div className="input-group-append">
                                    <button
                                      style={{ borderRadius: "5px" }}
                                      onClick={(e) => handleExportToExcel(e)}
                                      className="btn ripple btn-success btn-with-icon"
                                    >
                                      <i className="fa-solid fa-file-csv"></i>
                                      Export to Excel
                                    </button>
                                  </div>
                                </div>
                                <div className="form-group col-lg-6 col-md-6 mt-2">
                                  <div className="input-group-append">
                                    <button
                                      style={{ borderRadius: "5px" }}
                                      onClick={handleExportToPDF}
                                      className="btn ripple btn-secondary btn-with-icon"
                                    >
                                      <i className="fa-solid fa-file-pdf"></i>
                                      Export to PDF
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        {loading && <Loader />}
                        <table
                          className="table table-striped table-bordered"
                          style={{ border: "orange 3px groove" }}
                        >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Reff_No.</th>
                              <th>Project</th>
                              <th>Ledger Debited</th>
                              <th>Ledger Credited</th>
                              <th>Credit</th>
                              <th>Debit</th>
                              <th>Entry_type</th>
                              <th>trans_type</th>
                              <th>Details</th>
                              <th>Date</th>
                              <th>Reciever</th>
                              <th>Created By</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Transactions.length === 0 ? (
                              <tr>
                                <td colSpan={12}>
                                  <p className="text-center text-info">
                                    No data found in the system
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              Transactions.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item.folio_reff}</td>
                                  <td>{item.projectID?.project_name || ""}</td>
                                  <td>{item.ledgerID_debited.Ledger_name}</td>
                                  <td>{item.ledgerID_credited.Ledger_name}</td>
                                  <td>{item.credit.credit_p}</td>
                                  <td>{item.debit.debit_p}</td>
                                  <td>{item.entry_type}</td>
                                  <td>{item.trans_type}</td>
                                  <td
                                    style={{
                                      width: "200px",
                                      wordWrap: "break-word",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    {item.description}
                                  </td>
                                  <td>{item.date.short_date}</td>
                                  <td>{item.reciever}</td>
                                  <td>{item.created_by.name}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>

                        <nav className="mt-3" style={{ marginLeft: "30px" }}>
                          <ul className="pagination">
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={setPreviousPageNumber}
                              >
                                Prev
                              </button>
                            </li>
                            {meta.map((item) =>
                              page === item ? (
                                <li className="page-item active" key={item}>
                                  <a
                                    className="page-link"
                                    href="javascript:void(0);"
                                  >
                                    {item}
                                  </a>
                                </li>
                              ) : (
                                <li className="page-item" key={item}>
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={(e) => setPageNumber(e, item)}
                                  >
                                    {item}
                                  </a>
                                </li>
                              )
                            )}
                            <li className="page-item">
                              <button
                                className="page-link"
                                onClick={setNextPageNumber}
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default AllTransactionsView;
